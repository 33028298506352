import React, {useState, useEffect, useCallback, useRef} from 'react';

import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';

import { ImageLoader } from '../../../../components/imageloader';
import { home } from 'react-icons-kit/icomoon/home';

import pedido from '../../../../assets/image/pedido.png';
import restaurante from '../../../../assets/image/restaurante.png';
import preparo from '../../../../assets/image/preparo.png';
import entrega from '../../../../assets/image/entrega.png';
import cliente from '../../../../assets/image/cliente.png';

import { userRequests } from '../../../../services/api/user';


import '../../../../assets/style/pages/user/request/view/index.scss';
import { formatToMoney } from '../../../../services/utils';

export const PageUserRequestView = (props) => {

    const timeUpdate = 1000 * 30;

    let isValid = useRef(true);

    //console.log(props);
    
    const [request, setRequest] = useState(props?.location?.state?.data);
    //const [paymentType, setPaymentType] = useState();

    const time = useRef();

    //console.log(request);

    // const getLocalDelivery = () => {
    //     const address = request?.enderecos_data.filter(item => ~~item.id === ~~request?.esc_responsaveis_enderecos_id)[0];
    //     let str_address = '';
    //     if(address){
    //         str_address = ` ${address.endereco}, ${address.numero}${address.complemento ? `- ${address.complemento}`: ''} - ${address.bairro} - ${address.cidade}`;
    //     }else{
    //         str_address = 'Retirado no local/ Entregue mesa'
    //     }


    //     return str_address;
    // }

    // const getPaymentData = useCallback(() => {

    //    const getData = async() => {
    //         let list = [
    //             {label:'Dinheiro', value: 5 },
    //             {label:'Débito', value: 1 },
    //         ];

    //         const paymentdata =  await userPayment();

    //         paymentdata?.data?.map(item=> {
    //             list.push({label:`Crédito - ${item.portador} - ${item.numerotruncado}- ${item.val_mes}/${item.val_ano}`, value:~~item.id, portador: item.portador});
    //             return item;
    //         })

    //         const data = list.filter(item => ~~item.value === ~~request?.forma_pagamento)[0];

    //         setPaymentType(data?.label || '')
    //    }

    //    getData();
    // },[request])

    const getStatus = (_data) => {

        const sts_pedido = ~~_data?.status_pedido_id;
        const sts_producao = ~~_data?.status_producao_id;
        const status_entrega_id = ~~_data?.status_entrega_id;

        let _status = null;
        let icon = 'pi-spin pi-spinner';


        if(status_entrega_id){
            switch(~~status_entrega_id){
                case 0:
                    _status = 'Aguardando entregador';
                break;
                case 1:
                    _status = 'Em rota';
                break;
                case 2:
                    _status = 'Entregue';
                    icon = 'pi-check';
                break;
                default:
                    _status = status_entrega_id
            }

            if(_status)
                return <><i className={`p-mr-2 pi ${icon}`} />{_status}</>;
        }

        if(sts_producao){
            switch(~~sts_producao){
                case 0:
                    _status = 'Aguardando Restaurante';
                break;
                case 1:
                    _status = 'Em produção';
                break;
                case 2:
                    _status = 'Produção Finalizada';
                break;
                default:
                    _status = sts_producao
            }
            
            if(_status)
                return <><i className={`p-mr-2 pi ${icon}`} />{_status}</>;
        }
        
        if(sts_pedido && ~~sts_pedido === 1){
            _status = 'Pedido Aprovado';
        }else{
            _status = 'Aguardando aprovação';
        }

        if(~~_data?.cancelado === 1){
            _status = 'Cancelado';
            icon = 'pi-times';
        }


        return <><i className={`p-mr-2 pi ${icon}`} />{_status}</>;
    }

    const getData = useCallback(()=>{

        console.log('Pedido status');

        const getDataRequest = async () => {
            const data = await userRequests();
            let ref = request?.numero;
            const url = props.location.pathname.split('/');

            if(!ref){
               ref = url[url.length - 1]; 
            }
            //console.log('url', url);
            let req = data.data.filter(item => item.numero === ref)[0];
            //console.log(ref);
            if(req.data_cancelamento)
                req.data_cancelamento = req?.data_cancelamento ? 1 : 0;
            
            setRequest(req);

            clearTimeout(time.current)

            time.current = setTimeout(()=>{
                clearTimeout(time.current);
                isValid.current = true;
                getData();
            }, timeUpdate);

        }

        //console.log('URLLLL');
        
        getDataRequest();
        

    }, [timeUpdate, request, props])

    useEffect(()=>{
        
        

        if(isValid.current){
            isValid.current = false;
            getData();
        }

        //getData();
       
        //getPaymentData();
        //setTimeout(()=> getData(), timeUpdate);

        return(()=>{
            //clearTimeout(time.current);
            //isValid.current = true;
        })

    }, [getData]);

    return (
        <div className="page-user-request-view">
           <div className="main-frame">
                <div className="p-grid">
                    <div className="p-col-12 p-md-2">
                        <div className="avatar">
                            <ImageLoader image={`request?.empresa_data?.avatar`} default={home} />
                        </div>
                        <Button onClick={()=> props.history.push(`/${props.match.params.client}`) } style={{width: '100%', minWidth:'110px', fontSize:'0.8em'}} label="Ver cardápio" className="p-button-sm p-button-text" />
                    </div>
                    <div className="p-col-12 p-md-10" style={{fontSize:'0.8em'}}>
                        <div><strong style={{fontSize:'1.2em'}}>Pedido</strong>: {request?.numero}</div>
                        <h3><strong>{request?.empresa_data?.nome}</strong></h3>
                    </div>
                </div>
                <div className="p-grid p-mt-2 p-mb-2" style={{fontSize:'0.8em'}}>
                    <div className="p-col-12">
                        <strong>Entrega</strong>: {request?.endereco}
                    </div>
                </div>
                <div className="p-grid p-mt-2 p-mb-2" style={{fontSize:'0.8em'}}>
                    <div className="p-col-12">
                        <strong>Forma Pagamento</strong>: {request?.forma_pagamento}
                    </div>
                    <div className="p-col-12">
                        <strong>Valor</strong>: {formatToMoney(request?.total)}
                    </div>
                    <div className="p-col-12">
                        <strong>Troco</strong>: { parseFloat(request?.troco) > 0 ? formatToMoney(parseFloat(request?.troco) - parseFloat(request?.total)) : formatToMoney(parseFloat(request?.troco)) }
                    </div>
                </div>

                <div className="p-grid p-mt-2 p-mb-4" style={{fontSize:'0.8em'}}>
                    <div className="p-col-12 p-md-6">
                        <strong>Data pedido</strong>:{new Date(request?.data_emissao).toLocaleDateString()} {new Date(request?.data_emissao).toLocaleTimeString()}
                    </div>
                    <div className="p-col-12 p-md-6">
                        <div className="status">
                            {getStatus(request)}
                        </div>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-mb-4 p-mt-4">
                    <Panel toggleable collapsed  header="Itens pedido">
                        <ul className='itens-request'>
                            {
                                request?.items_data.map(item => {
                                    return <li>
                                       {item.quantidade} X {item.nome}
                                       {
                                           item.combo.length > 0 ?
                                            <ul>
                                                {item.combo.map(cb => <li>
                                                    {cb.quantidade} X {cb.nome}
                                                </li>)}
                                            </ul>
                                           :
                                            ''
                                       }
                                    </li>
                                })
                            }
                        </ul>
                    </Panel>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="status-steps">
                            <div className={`request ${request?.status_pedido_id && ~~request?.cancelado !== 1 ? 'active' : ''} status-${request?.status_pedido_id}`} >
                                <img src={pedido} alt='' />
                                <div className="text">
                                    Pedido
                                </div>
                            </div>
                            <div className={`store ${(request?.status_producao_id ) && ~~request?.cancelado !== 1 ? 'active' : ''} status-${request?.status_producao_id}`}>
                                <img src={restaurante} alt='' />
                                <div className="text">
                                    Restaurante
                                </div>
                            </div>
                            <div className={`production ${(request?.status_producao_id ? (~~request?.status_producao_id === 1 || ~~request?.status_producao_id === 2) : request?.status_producao_id) && ~~request?.cancelado !== 1 ? 'active' : ''} status-${request?.status_producao_id}`}>
                                <img src={preparo} alt='' />
                                <div className="text">
                                    Produção
                                </div>
                            </div>
                            <div className={`delivery ${(request?.status_entrega_id) && ~~request?.cancelado !== 1 ? 'active' : ''} status-${request?.status_entrega_id}`}>
                                <img src={entrega} alt='' />
                                <div className="text">
                                    Entrega
                                </div>
                            </div>
                            <div className={`client ${(request?.status_entrega_id ? (~~request?.status_entrega_id === 2) : request?.status_entrega_id) && ~~request?.cancelado !== 1 ? 'active' : ''} status-${request?.status_entrega_id}`}>
                                <img src={cliente} alt='' />
                                <div className="text">
                                    Cliente
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-grid p-m-5">
                    <div className="p-col-12" style={{textAlign:'center'}}>
                        <Button style={{width:'100px'}} onClick={()=> props.history.push(`/${props.match.params.client}/user/requests`)} label="Sair" className="p-button-danger p-button-sm" />
                    </div>
                </div>
                
           </div>
        </div>
    )
}