import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputText } from 'primereact/inputtext';
import { TabMenu } from 'primereact/tabmenu';

import { Dialog } from 'primereact/dialog';

import { getRequestsAllAdm, admRequestUpdate, getCoupon } from '../../../services/api/user';

import { formatToMoney } from '../../../services/utils';

import '../../../assets/style/pages/adm/request/index.scss';

export const PageAdmRequest = (props) => {

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showResume, setShowResume] = useState(null);

    const [globalFilter, setGlobalFilter] = useState();
    const [currentTab, setCurrentTab] = useState(0);

    const statusRef = useRef({});
    const tabRef = useRef(0);

    const tabs = useMemo(()=> [
        {label: 'Em andamento hoje', idx: 0, filter:'currentOpen'},
        {label: 'Em andamento', idx: 1, filter:'open'},
        {label: 'Concluídos hoje', idx: 2, filter: 'currentComplete'},
        {label: 'Concluídos', idx: 3, filter: 'complete'},
        {label: 'Cancelados hoje', idx: 4, filter: 'currentCancel'},
        {label: 'Cancelados', idx: 5, filter: 'cancel'}
    ], []);


    const getStatus = (_data) => {

        const sts_pedido = _data.status_pedido_id;
        const sts_producao = _data.status_producao_id;
        const sts_entrega = _data.status_entrega_id;

        let _status = null;
       // let icon = 'pi-spin pi-spinner';

        if(_data.data_cancelamento)
            return <>Cancelado</>



        if(sts_entrega){
            switch(~~sts_entrega){
                case 0:
                    _status = 'Aguardando entregador';
                break;
                case 1:
                    _status = 'Em rota';
                break;
                case 2:
                    _status = 'Entregue';
                    //icon = 'pi-check';
                break;
                default:
                    _status = sts_entrega
            }

            if(_status)
                return <>{_status}</>;
        }

        if(sts_producao){
            switch(~~sts_producao){
                case 0:
                    _status = 'Aguardando Restaurante';
                break;
                case 1:
                    _status = 'Em produção';
                break;
                case 2:
                    _status = 'Produção Finalizada';
                break;
                default:
                    _status = sts_producao
            }
            
            if(_status)
                return <>{_status}</>;
        }
        
        if(sts_pedido && ~~sts_pedido === 1){
            _status = 'Pedido Aprovado';
        }else{
            _status = 'Aguardando aprovação';
        }

        if(~~_data.cancelado === 1){
            _status = 'Cancelado';
            //icon = 'pi-times';
        }


        return <>{_status}</>;
    }


    


    const getData = useCallback(()=>{

        setIsLoading(true);

        const getDataBase = async () => {
            const request = await getRequestsAllAdm();
            let _data = request.data;
            
           // console.log(request.data);

            const filtersTab = {
                currentOpen: () =>{
                    _data = _data.filter(item => ~~item.status_entrega_id !== 2 );
                    _data = _data.filter(item => !item.data_cancelamento );
                    _data = _data.filter(item => new Date().toLocaleDateString() === new Date(item.data_emissao).toLocaleDateString() );
                },
                open: () =>{
                    _data = _data.filter(item => ~~item.status_entrega_id !== 2 );
                    _data = _data.filter(item => !item.data_cancelamento );
                },
                currentCancel: () => {
                    _data = _data.filter(item => item.data_cancelamento );
                    _data = _data.filter(item => new Date().toLocaleDateString() === new Date(item.data_cancelamento).toLocaleDateString());
                },
                cancel: () => {
                    _data = _data.filter(item => item.data_cancelamento );
                },
                currentComplete: () => {
                    _data = _data.filter(item => (item.data_finalizacao ?? ~~item.status_entrega_id === 2) && item.status_entrega_id );
                    _data = _data.filter(item => new Date().toLocaleDateString() === new Date(item.data_finalizacao).toLocaleDateString() &&  (item.data_finalizacao ?? ~~item.status_entrega_id === 2) );
                },
                complete: () => {
                    _data = _data.filter(item => (item.data_finalizacao ?? ~~item.status_entrega_id === 2) && item.status_entrega_id );
                }
            }

            const selectTab = tabs.filter(item => ~~item.idx === ~~tabRef.current)[0];

            // console.log('current tab index', tabRef.current);

            // console.log(selectTab);
            // console.dir(filtersTab['currenntComplete']);
            // console.log(filtersTab[selectTab.filter]);

            if(filtersTab[selectTab.filter]){
                filtersTab[selectTab.filter]();
            }

            //console.log('#',_data);

            setData(_data);

            setIsLoading(false);
        }

        getDataBase();

    },[tabs]);

    useEffect(()=>{
        getData();

    },[getData])

    const updateRequest = async (_data) => {
        setIsLoading(true);
        await admRequestUpdate(_data);
        getData();
    }

    const cancelRequest = async (_data) => {
        setIsLoading(true);
        const current_timetamp = new Date();
        await admRequestUpdate({id:_data.id, data_cancelamento: !_data.data_cancelamento ? `${current_timetamp.getFullYear()}-${current_timetamp.getMonth() + 1}-${current_timetamp.getDate()} ${current_timetamp.toTimeString()}` : null });
        getData();
    }

    const printRequest = async (data) => {
        
        const html = await getCoupon(data);
        const win = window.open('#','print', `innerWidth=216,height=${Screen.availHeight}`);
        win.document.write(html);
    }

    useEffect(()=>{

        console.log('Data');

        const timer = setTimeout(()=>{
            setIsLoading(true);
            clearTimeout(timer);
            getData();
        }, 1000 * 30);

        return(()=>{
            clearTimeout(timer);
        })

    }, [data, getData]);



    useEffect(()=>{
        tabRef.current = currentTab;
        getData();

    }, [currentTab, getData]);

    return(
        <div className="pageadm-request">
            <div className="main-frame">
                <h3>
                    Gerenciar pedidos
                </h3>
                {
                    data ?
                        <>
                        <TabMenu model={tabs} activeIndex={currentTab} onTabChange={(e) =>{ setCurrentTab(e.value.idx) }} />
                        <div className='datatable-responsive-demo'><div className='card'>
                        <DataTable 
                        className='p-datatable-responsive-demo'
                        header={<div style={{textAlign:'right'}}><span className="p-input-icon-left"><i className="pi pi-search" /><InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Busca pedido..." /></span></div>} globalFilter={globalFilter} 
                        paginator={data.length > 10} rows={10} loading={isLoading} value={data}>
                            <Column body={(_data)=>{
                                return <div><strong>Data: </strong>{`${new Date(_data.data_emissao).toLocaleDateString()} - ${new Date(_data.data_emissao).toLocaleTimeString()}`}</div>;
                            }

                            } header="Data"></Column>
                            <Column body={(row)=> <><strong>Número:</strong> {row.numero} </> } field="numero" header="Pedido"></Column>
                            <Column  body={(row)=>{
                                return <><strong>Mesa: </strong>{(/entregar na mesa/gi).test(row.endereco) ?  row.endereco.split(':')[1] : '-'}</>
                            }} header="Mesa"></Column>
                            <Column body={(row)=> <><strong>Ordem:</strong> {row.pedido_dia} </> } field="pedido_dia" header="Ordem"></Column>
                            <Column style={{width:'200px'}} body={(_data) => <><strong>Status atual:</strong> {getStatus(_data)}</> } header="Status"></Column>
                            {/* <Column style={{width:'100px'}} body={(_data) => formatToMoney(parseFloat(_data.total)) } header="Preço"></Column> */}
                            <Column style={{width:'260px'}} header="Ações" body={(row)=><>
                                <Button className='p-button-text' type="button" label="Status" icon='pi pi-arrow-down' iconPos='right' onClick={(e) => { statusRef.current[`request_${row.id}`].toggle(e)} } />
                                <OverlayPanel ref={(el)=> statusRef.current[`request_${row.id}`] = el }>
                                    <div className="grid">
                                        <div className="col-12 col-m-4">
                                            <p>
                                                <strong>
                                                    Alterar status pedido
                                                </strong>
                                            </p>
                                            <Dropdown disabled={row.data_cancelamento} style={{width:'100%'}} value={~~row.status_pedido_id + 1}  options={[
                                                {label:'Aguardando aprovação', value: 1},
                                                {label:'Pedido aprovado', value: 2}
                                            ]} onChange={(e) => {
                                                updateRequest({id: ~~row.id, status_pedido_id: ~~e.target.value - 1});
                                            }} />
                                        </div>

                                        <div style={{PointerEvent: ~~row.status_pedido < 1 ? 'none' : 'auto'}} className="col-12 col-m-4 p-mt-3">
                                            <p>
                                                <strong>
                                                    Alterar status produção
                                                </strong>
                                            </p>
                                            <Dropdown disabled={~~row.status_pedido_id < 1 || row.data_cancelamento} style={{width:'100%'}} value={~~row.status_producao_id + 1}  options={[
                                                {label:'Aguardando Restaurante', value: 1},
                                                {label:'Em produção', value: 2},
                                                {label:'Produção Finalizada', value: 3}
                                            ]} onChange={(e) => {
                                                updateRequest({id: ~~row.id, status_producao_id : ~~e.target.value - 1});
                                            }} />
                                        </div>

                                        <div style={{PointerEvent: ~~row.status_pedido < 1 ? 'none' : 'auto'}} className="col-12 col-m-4 p-mt-3">
                                            <p>
                                                <strong>
                                                    Alterar status produção
                                                </strong>
                                            </p>
                                            <Dropdown disabled={~~row.status_producao_id < 2 || row.data_cancelamento } style={{width:'100%'}} value={~~row.status_entrega_id + 1}  options={[
                                                {label:'Aguardando entregador', value: 1},
                                                {label:'Em rota', value: 2},
                                                {label:'Entregue', value: 3}
                                            ]} onChange={(e) => {
                                                updateRequest({id: ~~row.id, status_entrega_id : ~~e.target.value - 1});
                                            }} />
                                        </div>
                                        <div className="col-12 col-m-4 p-mt-3 jc-center">
                                            <Button style={{width:'100%'}} className='p-button-danger p-mt-2' type="button" label={!row.data_cancelamento ? `Cancelar pedido` : `Reativar pedido`}  onClick={(e) => { cancelRequest(row) }} />
                                        </div>
                                    </div>
                                </OverlayPanel>
                                <Button className='p-button-text' type="button" label="Detalhes" icon='pi pi-search' iconPos='right' onClick={(e) => { setShowResume(`request_${row.id}`) }} />
                                <Dialog footer={()=>{
                                    return ( ~~row.status_entrega_id !== 2 && !row.data_cancelamento ?
                                            <Button onClick={()=> printRequest(row.id)} label='Imprimir' />
                                            :
                                            null
                                    )
                                }} maximizable  header={`Pedido - ${row.numero}`} visible={showResume === `request_${row.id}`} style={{ maxWidth: '90%', minWidth: '50vw' }} onHide={() => setShowResume(null)}>
                                    <div className={`preview-${row.id}`}>
                                        <p>
                                            <strong>Número pedido do dia</strong>: {row.pedido_dia}
                                        </p>
                                        <p>
                                            <strong>Status pedido</strong>: { getStatus(row)} {row.data_finalizacao ? ` - ${new Date(row.data_finalizacao).toLocaleDateString()} - ${new Date(row.data_finalizacao).toLocaleTimeString()}` : '' } {row.data_cancelamento ? ` - ${new Date(row.data_cancelamento).toLocaleDateString()} - ${new Date(row.data_cancelamento).toLocaleTimeString()}` : '' }
                                        </p>
                                        <p>
                                            <strong>Data</strong>: {`${new Date(row.data_emissao).toLocaleDateString()} - ${new Date(row.data_emissao).toLocaleTimeString()}`}
                                        </p>
                                        <p>
                                            <strong>Entrega</strong>: {row.endereco}
                                        </p>
                                        <p>
                                        <strong>Forma pagamento</strong>: {row.forma_pagamento} - <strong>Total</strong>: {formatToMoney(parseFloat(row.total))} - <strong>Troco</strong>: {formatToMoney(parseFloat(row.troco))}
                                        </p>

                                        <p>
                                            <strong>Cliente</strong>: {row.usuario_nome}
                                        </p>
                                        <p>
                                            <strong>Contato</strong>: {row.celular} - {row.telefone}
                                        </p>
                                        <div>
                                            {
                                                row.produtos.length > 0 ?
                                                    <strong>
                                                        Items
                                                    </strong>
                                                : 
                                                ''
                                            }
                                            <ul>
                                                {
                                                    row.produtos.map(item => <li>
                                                        {item.quantidade} x {item.nome}
                                                        {
                                                            item.items.length > 0 ?
                                                            <ul>
                                                                    {
                                                                        item.items.map(ele => <li>{ele.quantidade} x {ele.nome}</li> )
                                                                    }
                                                                </ul>
                                                            :
                                                            ''
                                                        }
                                                        <div style={{fontSize:'0.8em'}}>{ item.informacao }</div>
                                                    </li>

                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </Dialog>
                            </>}></Column>
                        </DataTable>
                        </div></div>
                        </>
                    :
                    <div style={{textAlign: 'center', height:'200px'}}> <ProgressSpinner style={{width: '36px', height: '36px', margin: '10px auto'}} /> </div>
                }
            </div>
        </div>
    )
}