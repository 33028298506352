import React, { useEffect, useState, useCallback, useRef } from 'react';

import { useAPP } from '../../../services/provider/app';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { getdeliverylist, getRoute, getMyRoute, updateDelivery } from '../../../services/api/user';
import { URL_GOOGLE_MAPS_GETROUTER } from '../../../services/api';

import '../../../assets/style/pages/delivery/request/index.scss';

export const PageDeliveryRequests = (props) => {

    const [list, setList] = useState([]);
    const [myRoutes, setMyRoutes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [globalFilter, setGlobalFilter] = useState();
    
    const { user,  company } = useAPP();

    const timer = useRef();

    const getListData = useCallback(async () => {
        if(user?.empresa_id){
            const data = await getdeliverylist(user?.empresa_id);
            setList(data.data);
        }

    }, [user]);

    const getMyRoutes = useCallback(async ()=>{

        if(user?.empresa_id){
            const data = await getMyRoute(user?.empresa_id);
            setMyRoutes(data.data);
        }

    }, [user])

    const checkGetRoute = async (id) => {


        await getRoute(id);

        let pedido_data =  list.filter(item => ~~item.id === ~~id);
        pedido_data =  pedido_data ? pedido_data[0] : null;

        let maps =  null;
        let origin = null;
        let destination = null;

        if(pedido_data?.maps_data){
            maps = JSON.parse(pedido_data.maps_data);
            origin = maps.origin_addresses[0];
            destination = maps.destination_addresses[0];
        }

        // if(origin && destination){
        //     const url = `${URL_GOOGLE_MAPS_GETROUTER}&destination=${destination}&origin=${origin}`;

        //     const params = {
        //         method: 'GET',
        //         header: new Headers()
        //     }

        //     const maps_data_object =  await fetch(url, params);
        //     const maps_data = await maps_data_object.json();
        //     //console.log(maps_data);

        //     const direction = JSON.stringify(maps_data);

        //     const result = await updateDelivery({id:id, maps_direction: direction});

        //     //console.log(result);

        // }

    }

    const goRoute = (id) => {
        props.history.push(`/adm/inroute/${id}`);
    }

    useEffect(()=>{
        getListData()
        getMyRoutes();
    }, [getListData, getMyRoutes]);

    useEffect(()=>{

        const updateTimer = () => {
            timer.current = setTimeout(()=>{
                clearTimeout(timer.current);
                getListData();
                getMyRoutes();
                updateTimer();
            }, 5000)
        }

        updateTimer();

        return(()=>{
            clearTimeout(timer.current);
        })

    }, [])

    return (
        <div className="page-delivery-requests">
            <div className="main-frame">
                <h3>
                    Minhas rotas
                </h3>
                <DataTable 
                    className='p-datatable-responsive-demo'
                    emptyMessage="Não há pedidos para entrega" 
                    paginator={myRoutes?.length > 10} rows={10} loading={isLoading} value={myRoutes}>
                        <Column style={{width:'200px'}} body={(row)=> <>{row.numero} </> } field="numero" header="Pedido"></Column>
                        <Column body={(row)=> <>{row.endereco} </> } field="endereco" header="Endereço"></Column>
                        <Column style={{width:'200px', textAlign:'right'}} body={(row)=> <> 
                            <Button onClick={()=> goRoute(row.id)} label='Acessar rota' />
                        </> } ></Column>
                </DataTable>
                <h3>
                    Lista de pedidos
                </h3>
                <DataTable 
                    className='p-datatable-responsive-demo'
                    emptyMessage="Não há pedidos em aberto"
                    header={<div style={{textAlign:'right'}}><span className="p-input-icon-left"><i className="pi pi-search" /><InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Busca pedido..." /></span></div>} globalFilter={globalFilter} 
                    paginator={list?.length > 10} rows={10} loading={isLoading} value={list}>
                        <Column style={{width:'200px'}} body={(row)=> <>{row.numero} </> } field="numero" header="Pedido"></Column>
                        <Column body={(row)=> <>{row.endereco} </> } field="endereco" header="Endereço"></Column>
                        <Column style={{width:'200px', textAlign:'right'}} body={(row)=> <> 
                            <Button onClick={()=> checkGetRoute(row.id)} label='Fazer entrega' />
                        </> } ></Column>
                </DataTable>
            </div>
        </div>
    )
}