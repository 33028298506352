import {URL_API} from '../';
import { getToken } from '../../auth';

export const getEmployeed = async (id = null) => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'GET',
        headers: header
    };

    let paramid = '';

    if(id)
        paramid = `/${id}`;

    const request = await fetch(`${URL_API}employee${paramid}`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const getEmployeedFunction = async () => {


    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'GET',
        headers: header
    };


    const request = await fetch(`${URL_API}employee/function`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;
}

export const createEmployeed = async (data) => {
    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(data)
    };


    const request = await fetch(`${URL_API}employee/create`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;
}

export const createEmployeedFunction = async (data) => {
    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(data)
    };


    const request = await fetch(`${URL_API}employee/function/create`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;
}

export const updateEmployeedFunction = async (data) => {
    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'PUT',
        headers: header,
        body: JSON.stringify(data)
    };


    const request = await fetch(`${URL_API}employee/function/update`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;
}

export const deleteAllEmployeedFunction = async (data) => {
    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'DELETE',
        headers: header,
        body: JSON.stringify(data)
    };


    const request = await fetch(`${URL_API}employee/function/deleteall`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;
}

export const updateEmployeed = async (data) => {
    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'PUT',
        headers: header,
        body: JSON.stringify(data)
    };


    const request = await fetch(`${URL_API}employee/update`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;
}


export const deleteEmployeed = async (data) => {
    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'DELETE',
        headers: header,
        body: JSON.stringify(data)
    };


    const request = await fetch(`${URL_API}employee/delete`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;
}