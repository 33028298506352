import React, { useState, useEffect, useRef} from 'react';

import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog , confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


import { getToken } from '../../../services/auth';

import { getStoreCategories, storeCategoryProductUpdate, storeCategoryProductDelete, storeCategoryProductCreate } from '../../../services/api/store';

import '../../../assets/style/pages/adm/category/index.scss';

export const PageAdmProductCategory = (props) => {


    const [categoryList, setCategoryList] = useState(null);
    const [categoryEdit, setCategoryEdit] = useState(null);
    const [categoryCreate, setCategoryCreate] = useState(null);
    const [showEdit, setShowEdit] = useState(false);
    const [isLoad, setIsLoad] = useState(false);

    const [globalFilter, setGlobalFilter] = useState();

    const refEdit = useRef();
    
    const getData = async () => {
        const token = getToken();
        const store =  JSON.parse(atob(token));

        const data = await getStoreCategories(store.empresa_id, true);
        
        setCategoryList(data.data);
        setIsLoad(false);
    }

    const deleteCategory = async (_data) => {
       
        confirmDialog({
            message: <p>Deseja realmente apagar este item?</p>,
            header: 'Confirmação',
            acceptLabel:'Sim',
            rejectLabel:'Não',
            acceptClassName:'p-button-danger',
            accept: async () => {
                await storeCategoryProductDelete(_data);

                setIsLoad(true);
                getData();
            },
            reject: () => {}
        });
    }

    const updateCategory = async (_data) => {

        setIsLoad(true);
        await storeCategoryProductUpdate(_data);

        getData();
    }

    const createCategory = async () => {

        setIsLoad(true);
        await storeCategoryProductCreate({nome: categoryCreate});
        setCategoryCreate('');

        getData();
    }



    useEffect(()=> {
        setIsLoad(true);
        getData();
    }, []);

    return (
        <div className="page-adm-productcategory">
            <div className="main-frame">
                <h3>
                    Gerenciar categorias de produtos
                </h3>
                <Divider>
                    <i className="pi pi-list p-mr-2" />
                    <strong style={{fontSize:'1em'}}>
                        Criar nova categoria
                    </strong>
                </Divider>
                <div className="p-grid p-mt-4">
                    <div className="p-col-12">
                        <div className="p-inputgroup">
                            <InputText value={categoryCreate} onChange={(e)=> setCategoryCreate(e.target.value)} placeholder="Nome categoria"/>
                            <Button onClick={()=> createCategory() } loading={isLoad} label="Criar nova categoria"/>
                        </div>
                    </div>
                </div>
                <Divider />
                <DataTable 
                header={<div style={{textAlign:'right'}}><span className="p-input-icon-left"><i className="pi pi-search" /><InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Busca..." /></span></div>} globalFilter={globalFilter} 
                loading={isLoad} value={categoryList} paginator={categoryList?.length > 10} rows={10} responsiveLayout="scroll">
                <Column body={(row)=>{
                    return <div>
                        {row.nome}
                    </div>
                }} field="nome" header="Categoria"></Column>

                <Column style={{width:'150px'}} body={(row)=>{
                    return <>
                            <Button onClick={()=>{setCategoryEdit(row.nome); setShowEdit(true); refEdit.current = row; }} icon="pi pi-pencil" className="p-button-text p-button" />
                            <Button onClick={()=> updateCategory({...row, ativo: !(~~row.ativo === 1) })} icon={`pi ${~~row.ativo === 1 ? 'pi-eye' : 'pi-eye-slash' }`} className="p-button-text p-button-success" />
                            <Button onClick={()=> deleteCategory(row)} icon="pi pi-trash" className="p-button-text p-button-danger" />
                        </>
                }} header=""></Column>
            </DataTable>
            <ConfirmDialog visible={showEdit} onHide={() => setShowEdit(false)} message={()=>{
                return  <>
                         <InputText value={categoryEdit} onChange={(e)=> setCategoryEdit(e.target.value)} placeholder="Nome categoria"/>
                     </>
            }} header="Atualizar" acceptLabel='Salvar'
            rejectLabel='Cancelar'
            acceptClassName='p-button p-mt-2'
            rejectClassName='p-button-danger'
            accept={()=>{
                updateCategory({...refEdit.current, nome: categoryEdit});
            }}
            reject={()=> {}} />
            </div>
        </div>
    )
}