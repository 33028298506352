import React, {useEffect, useState, useRef} from 'react';

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { userAddress, userAddressDelete } from '../../../services/api/user';

import '../../../assets/style/pages/user/address/index.scss';

export const PageUserAddress = (props) => {

    const [addressList, setAddressList] = useState(null);
    const [isLoad, setIsLoad] = useState(false);

    const toast = useRef(null);

    const getData = async () => {
        const data = await userAddress();
        setAddressList(data.data);
        setIsLoad(false);
    }

    const deleteAddress = async (_data) => {

        confirmDialog({
            message: <p>Deseja realmente apagar este item?</p>,
            header: 'Confirmação',
            acceptLabel:'Sim',
            rejectLabel:'Não',
            acceptClassName:'p-button-danger',
            accept: async () => {
                //document.querySelector(`#address-item-${_data.id}`).classList.add('delete');
                const deletePaymentRequest = await userAddressDelete(_data);

                if(deletePaymentRequest.data){
                    toast.current.show({severity: 'success', summary: 'Sucesso', detail: 'Endereço deletado com sucesso.'});
                }else{
                    toast.current.show({severity: 'error', summary: 'Erro', detail: 'Endereço não deletado. Tente novamente.'});
                    document.querySelector(`#address-item-${_data.id}`).classList.remove('delete');
                }
                setIsLoad(true);
                getData();
            },
            reject: () => {}
        });
    }

    useEffect(()=>{
        setIsLoad(true);
        getData();

    }, []);

    return (
        <div className="page-user-address">
            <div className="main-frame">
                <h3>
                    Lista endereços
                </h3>
                <div className="p-d-flex p-mb-4 p-mt-2 p-jc-end">
                    <Button onClick={()=>props.history.push(`/${props.match.params.client}/user/address/create`)} className="p-button-text" label="Adicionar novo endereço" />
                </div>
                <DataTable loading={isLoad} value={addressList} paginator={addressList?.length > 10} rows={10} responsiveLayout="scroll">
                    <Column body={(row)=>{
                        return <div>
                            <div><strong>{row.titulo}</strong></div>
                            <div>
                                {row.logradouro}, {row.numero}{row.comple ? `- ${row.comple}`: ''} - {row.bairro} - {row.cidade}
                            </div>
                            <div style={{fontSize:'0.8em'}} className="p-mt-2">
                                {row.referencia}
                            </div>
                        </div>
                    }} header="Endereço"></Column>

                    <Column style={{width:'120px'}} body={(row)=>{
                        return <>
                                <Button onClick={()=> props.history.push({pathname: `/${props.match.params.client}/user/address/update/${row.id}`, state:{data: row}})} icon="pi pi-pencil" className="p-button-text p-button" />
                                <Button onClick={()=> deleteAddress(row)} icon="pi pi-trash" className="p-button-text p-button-danger" />
                            </>
                    }} header=""></Column>
                </DataTable>
                {/* {
                    addressList ?
                        <>
                            {
                                addressList.length > 0 ?
                                    <div>
                                        {
                                            addressList.map((item, id) => {
                                                return <div key={`address-item-${id}`} id={`address-item-${item.id}`} className="card">
                                                    <div className="p-grid p-ai-center">
                                                        <div className="p-col">
                                                            <div>
                                                                {item.logradouro}, {item.numero}{item.comple ? `- ${item.comple}`: ''} - {item.bairro} - {item.cidade}
                                                            </div>
                                                            <div style={{fontSize:'0.8em'}} className="p-mt-2">
                                                                {item.referencia}
                                                            </div>
                                                        </div>
                                                        <div className="p-col-fixed" style={{width:'100px'}}>
                                                            <Button onClick={()=> props.history.push({pathname: `/${props.match.params.client}/user/address/update/${item.id}`, state:{data: item}})} icon="pi pi-pencil" className="p-button-text p-button" />
                                                            <Button onClick={()=> deleteAddress(item)} icon="pi pi-trash" className="p-button-text p-button-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                :   
                                <p>
                                    Não há endereços cadastrados.
                                </p>
                            }
                        </>
                    :
                        <div style={{textAlign: 'center', height:'200px'}}> <ProgressSpinner style={{width: '36px', height: '36px', margin: '10px auto'}} /> </div>
                } */}
            </div>
            <Toast ref={toast} position="bottom-center" />
        </div>
    )
}