import React, {useState, useRef, useMemo} from 'react';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';

import { userPaymentCreate } from '../../../../services/api/user';


import { useForm, Controller } from 'react-hook-form';

import { getCreditCardFlag, cards, localeData } from '../../../../services/utils';

import '../../../../assets/style/pages/user/cards/create/index.scss';

export const PageUserPaymentCreate = (props) => {

    const defaultValues = {
        nome:'',
        numero:'',
        forma_pagamento_id:'',
        bandeira:'',
        portador:'',
        validade_mes:'',
        validade_ano:'',
        ccv:''
    }

    const cardsType = useMemo(()=>[
        {label:'Crédito', value:'credit'},
        {label:'Débito', value:'debit'},
        {label:'Voucher', value:'voucher'},
    ], []);

    const months = localeData.br.monthNames.map((item, id) => {
        return {label: item, value: id + 1}
    })

    const listYears = [];
    listYears.length = 10;
    listYears.fill(0);

    const years = listYears.map((item, id)=>{
        const date = new Date().getFullYear();
        return {label: date + id, value: date + id};
    })

    const [isLoad, setIsLoad] = useState(false);

    const form = useForm({ defaultValues });
    const control =  form.control;
    const errors  = form.formState.errors ;
    const handleSubmit = form.handleSubmit;

    const toast = useRef(null);

    const verifyComplete = (e) => {
        if(e?.onCallback){
            e.onCallback();
        }
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const searchFlag = (_data) => {
        const data = getCreditCardFlag(_data);

        if(data){
            form.setValue('bandeira', data);
        }else{
            form.setValue('bandeira', '');
        }
    }

    const onSubmit = async (_data) => {
        setIsLoad(true);

        const data = await userPaymentCreate(_data);

        setIsLoad(false);

        if(data.data){
            toast.current.show({severity: 'success', summary: 'Sucesso', detail: 'Cartão cadastrado.', onCallback:()=>( props?.location?.state?.callback ? props.history.push(props.location.state.callback) : props.history.push(`/${props.match.params.client}/user/payment`))});
        }else{
            toast.current.show({severity: 'error', summary: 'Erro', detail: 'Cartão não cadastrado. Tente novamente.'});
        }
    }

    return (
        <div className="page-user-payment-create">
            <div className="main-frame">
            <form onSubmit={handleSubmit(onSubmit)}>
                    <h3>
                        Cadastro de forma de pagamento
                    </h3>
                    <div className="p-grid p-mt-4">
                        <div className="p-col-12">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="nome" control={control} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="nome">Título</label>
                                </span>
                                {getFormErrorMessage('nome')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-4">
                            <div className="p-field">
                                <span className="p-float-label p-input-icon-right" style={{width:'100%'}}>
                                    <Controller name="numero" control={control} rules={{required:'Campo obrigatório'}} render={({ field, fieldState }) => (
                                        <InputText value={field.value} onChange={(e)=> field.onChange(e.target.value)} onInput={(e) => searchFlag(e.target.value)} style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="numero">Número *</label>
                                </span>
                                {getFormErrorMessage('numero')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="bandeira" control={control} rules={{required:'Campo obrigatório'}} render={({ field, fieldState }) => (
                                        <AutoComplete inputStyle={{width:'100%'}} suggestions={Object.keys(cards)}  id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="bandeira">Bandeira *</label>
                                </span>
                                {getFormErrorMessage('bandeira')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="portador" control={control} rules={{required:'Campo obrigatório'}} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="portador">Portador *</label>
                                </span>
                                {getFormErrorMessage('portador')}
                            </div>
                        </div>
                    </div>

                    <div className="p-grid p-mt-2 p-ai-center">
                        <div className="p-col-12 p-md-1" style={{fontSize:'0.8em'}}>
                            <strong>Validade</strong>:
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="validade_mes" control={control} rules={{required:'Campo obrigatório'}} render={({ field, fieldState }) => (
                                        <Dropdown value={field.value} onChange={(e) => field.onChange(e.value)} options={months} style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="validade_mes">Mês *</label>
                                </span>
                                {getFormErrorMessage('validade_mes')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="validade_ano" control={control} rules={{required:'Campo obrigatório'}} render={({ field, fieldState }) => (
                                        <Dropdown value={field.value} onChange={(e) => field.onChange(e.value)} options={years} style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="validade_ano">Ano *</label>
                                </span>
                                {getFormErrorMessage('validade_ano')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="ccv" control={control} rules={{required:'Campo obrigatório'}} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="ccv">Digito verificador (CVV) *</label>
                                </span>
                                {getFormErrorMessage('ccv')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="tipo_cartao" control={control} rules={{required:'Campo obrigatório'}} render={({ field, fieldState }) => (
                                        <Dropdown value={field.value} onChange={(e) => field.onChange(e.value)} options={cardsType} style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="tipo_cartao">Tipo *</label>
                                </span>
                                {getFormErrorMessage('tipo_cartao')}
                            </div>
                        </div>
                    </div>
                    <div className="p-d-flex p-mt-2 p-jc-end">
                        <Button onClick={()=> ( props?.location?.state?.callback ? props.history.push(props.location.state.callback) : props.history.push(`/${props.match.params.client}/user/payment`))} label="Cancelar" className="p-button-danger p-mr-2" style={{width:'100px'}} />
                        <Button type="submit" loading={isLoad} label="Cadastrar" className="p-button" style={{width:'100px'}} />
                    </div>
                </form>
            </div>
            <Toast ref={toast} position="bottom-center" onHide={(e)=> verifyComplete(e)} onRemove={(e)=> verifyComplete(e)} />
        </div>
    )
}