import React from 'react';
import ReactDOM from 'react-dom';
import {MainRouter} from './components/navigation/main';
import './assets/style/index.scss';
import {AppProvider} from './services/provider/app';

ReactDOM.render(<React.StrictMode>
        <AppProvider>
            <MainRouter />
        </AppProvider>
    </React.StrictMode>,document.getElementById('root'));

