import React, {useState, useEffect, useRef, useCallback, useMemo} from 'react';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';


import { getEmployeedFunction, updateEmployeed, createEmployeedFunction, getEmployeed, deleteAllEmployeedFunction } from '../../../../services/api/employee';

import { useForm, Controller } from 'react-hook-form';

import { getToken } from '../../../../services/auth';

import '../../../../assets/style/pages/adm/employee/index.scss';

export const PageAdmEmployeeUpdate = (props) => {


    const defaultForm = useMemo(() => 
        ({
            nome: '',
            cpf: '',
            password: '',
            ativo: 1
        }),
        []
    );
    
    const form = useForm({defaultForm});

    const control = form.control;
    const errors = form.formState.errors;
    //const reset  = form.reset;
    const handleSubmit =  form.handleSubmit;

    const [cargos, setCargos] = useState([]);
    const [user, setUser] = useState(null);

    const [isLoad, setIsLoad] = useState(false);

    const message =  useRef();

    const verifyComplete = (e) => {
        if(e?.onCallback){
            e.onCallback();
        }
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const onSubmit = async (data) => {
        

        setIsLoad(true);

        let cargosList = [];
        Object.keys(data).map(item => {

            if(/cargo/gi.test(item)){
                if(data[item]){
                    cargosList.push(~~item.replace(/\D/gi,''));
                }
            }

            return item;
        })

        if(cargosList.length === 0){
            setIsLoad(false);
            message.current.show({severity: 'error', summary: 'Erro', detail: 'Selecione um ou mais cargos'});
            return;
        }

        let usertemp = {id: user.id };


        await deleteAllEmployeedFunction({funcionario_id: user.id});

        Object.keys(defaultForm).map(item => {

            if(data[item]){
                usertemp[item] = data[item];
            }

            return item;
        })

        await updateEmployeed(usertemp);

        const functionsList = cargosList.map(item => createEmployeedFunction({ funcionario_id: user.id, tipo_funcionario_id: item }) );
        await Promise.all(functionsList);
        message.current.show({severity: 'success', summary: 'Sucesso', detail: 'Funcionário atualizado', onCallback:()=> ( props?.location?.state?.callback ? props.history.push(props.location.state.callback) : props.history.push(`/adm/employee`))  });

        setIsLoad(false);

    }

    const getData = useCallback( () => {
        
        const getDataContent = async () => {
            const data = await getEmployeedFunction();
            setCargos(data.data);

            const userData = props?.history?.location?.state?.data;
            const userID = userData?.id;
            
            const usertemp = await getEmployeed(userID);

            
            const token = getToken();
            const store =  JSON.parse(atob(token));

            if(~~usertemp.data.empresa_id === ~~userData?.empresa_id &&  ~~usertemp.data.empresa_id === ~~store.empresa_id){
                
                setUser(usertemp.data);

                Object.keys(defaultForm).map(item => {
                    defaultForm[item] = usertemp.data[item];
                    if(usertemp.data[item])
                        form.setValue(item,  usertemp.data[item]);
                    return item;
                })

                usertemp.data?.cargos?.map(item => {
                    
                    form.setValue(`cargo_${item.id}`, true);
                    
                    return item;
                })

            }else{
                message?.current?.show({severity: 'error', summary: 'Erro', detail: 'Funcionário não cadastrado', onCallback:()=> ( props?.location?.state?.callback ? props.history.push(props.location.state.callback) : props.history.push(`/adm/employee`))});    
            }

        }
        
        getDataContent();

    },[defaultForm, props, form]);

    useEffect(()=>{

        getData();

    }, [getData])

    return (
        <div className="pageadm-employeecreate">
            <div className="main-frame">
                <h3>
                    Criar funcionário
                </h3>
                <form onSubmit={handleSubmit(onSubmit)} >
                    <div className="p-grid p-mt-4">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name={`nome`}  control={control}  render={({ field, fieldState }) => (
                                        <InputText required type="text" style={{width:'100%'}} id={field.name} {...field} value={field.value} onChange={(e)=> field.onChange(e.target.value) } />
                                    )} />
                                    <label htmlFor={`nome`}>Nome</label>
                                </span>
                                {getFormErrorMessage(`nome`)}
                            </div>
                        </div>

                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name={`cpf`}  control={control}   render={({ field, fieldState }) => (
                                        <InputText required type="text" style={{width:'100%'}} id={field.name} {...field} value={field.value} onChange={(e)=> field.onChange(e.target.value.replace(/\D/gi, '') ) }/>
                                    )} />
                                    <label htmlFor={`cpf`}>CPF</label>
                                </span>
                                {getFormErrorMessage(`cpf`)}
                            </div>
                        </div>

                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name={`password`}  control={control}  render={({ field, fieldState }) => (
                                        <Password toggleMask feedback={false} inputStyle={{width:'100%'}} type="text" style={{width:'100%'}} id={field.name} {...field} value={field.value} onChange={(e)=> field.onChange(e.target.value) } />
                                    )} />
                                    <label htmlFor={`password`}>Senha</label>
                                </span>
                                {getFormErrorMessage(`password`)}
                            </div>
                        </div>

                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name={`ativo`}  control={control}  render={({ field, fieldState }) => (
                                        <Dropdown style={{width:'100%'}} optionLabel="name" value={field.value} options={[{value:'1', name:'Ativo'}, {value:'0', name:'Desativado'}]} onChange={(e)=> field.onChange(e.value)} id={field.name} {...field} placeholder="Select o status"/>
                                    )} />
                                    <label htmlFor={`ativo`}>Status</label>
                                </span>
                                {getFormErrorMessage(`ativo`)}
                            </div>
                        </div>

                        <div className="p-col-12 p-mb-4">
                            <DataTable value={cargos} responsiveLayout="scroll">
                                <Column  body={(row)=>{
                                    return <div style={{textTransform:'capitalize'}}>
                                        {row.nome}
                                    </div>
                                }} header="Cargo"></Column>
                                <Column style={{width:'80px', textAlign:'center'}} body={(row)=>{
                                    return <div>
                                    <Controller name={`cargo_${row.id}`} control={control} render={({ field, fieldState }) => (
                                            <Checkbox onChange={e => field.onChange(e.checked)} checked={field.value} id={field.name} value={field.value} {...field} ></Checkbox>
                                        )} />
                                    </div>
                                }} header="Status"></Column>

                            </DataTable>
                        </div>

                        
                    </div>

                    <div className="p-d-flex p-mt-2 p-jc-end p-mt-7">
                        <Button onClick={()=> ( props?.location?.state?.callback ? props.history.push(props.location.state.callback) : props.history.push(`/adm/employee`)) } label="Cancelar" className="p-button-danger p-mr-2" style={{width:'100px'}} />
                        <Button type="submit" loading={isLoad} label="Atualizar" className="p-button" style={{width:'100px'}} />
                    </div>
                </form>
                <Toast ref={message} onHide={(e)=> verifyComplete(e)} onRemove={(e)=> verifyComplete(e)} />
            </div>
        </div>
    )
}