import React, {useState, useEffect} from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import {InputSwitch} from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';


import { getStoreProducts } from '../../../services/api/store';
import { getToken } from '../../../services/auth';
import { userAvailableItem } from '../../../services/api/user';

import '../../../assets/style/pages/adm/availableitem/index.scss';

export const PageAdmAvailableItem = (props) => {

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [globalFilter, setGlobalFilter] = useState();

    const getList = async () => {
        
        setIsLoading(true);

        const token = getToken();
        const store =  JSON.parse(atob(token));

        const dataProducts = await getStoreProducts(store.empresa_id, true);
        let dataTemp = [];
        ////console.log(dataProducts.data);
        dataProducts.data?.map(item =>{
            dataTemp = [...dataTemp, item];
            if(item.combo){
                item.combo = item.combo.map(it => {
                    it.nome_produto_pai = item.nome;
                    return it;
                })
                dataTemp = [...dataTemp, ...item.combo];
            }
            return item;
        })

        dataTemp = dataTemp.filter(item => item.id);

        setIsLoading(false);

        setData(dataTemp);
    }

    const updateStatus = async (data) => {
        setIsLoading(true);
        await userAvailableItem({id:data.id, ativo: ~~data.ativo === 1 ? 0 : 1});
        await getList();
    }

    useEffect(()=>{

        const getData = async () => {
            await getList();
        }

        getData();
        
    }, []);

    return(
        <div className="pageadm-availableitem"> 
            <div className="main-frame">
                <h3>
                    Gerenciar itens disponíveis
                </h3>
                <DataTable 
                header={<div style={{textAlign:'right'}}><span className="p-input-icon-left"><i className="pi pi-search" /><InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Busca produto..." /></span></div>} globalFilter={globalFilter} 
                loading={isLoading} value={data} >
                        <Column field="nome" header="Produto" sortable />
                        <Column body={(row)=> <>
                            {row.nome_categoria ? row.nome_categoria : `Combo Item ${row.nome_produto_pai ? `: ${row.nome_produto_pai}` : ''}`}
                        </>} field="nome_categoria" header="Categória" sortable />
                        <Column style={{width:'130px'}} field="ativo" header="Status" sortable body={(row)=><>
                            <div style={{textAlign:'center'}}>
                                {~~row.ativo === 1 ? <Tag value="Ativo" severity="success"></Tag> : <Tag value="Desativado" severity="danger"></Tag>}
                            </div>
                        </>} />
                        <Column style={{width:'130px', textAlign:'center'}} header="Trocar Status" body={(row)=><>
                            <InputSwitch checked={~~row.ativo === 1} onChange={(e) => updateStatus(row)} />
                        </>} />
                </DataTable>
            </div>
        </div>
    )

}