import React, { useRef, useEffect, useState, useCallback } from 'react';

import { getMyRoute, updateDelivery } from '../../../services/api/user';


import {
    useJsApiLoader,
    GoogleMap,
    Marker,
    DirectionsService,
    DirectionsRenderer,
  } from '@react-google-maps/api'

import { useAPP } from '../../../services/provider/app';
import { TOKEN_GOOGLE_MAPS } from '../../../services/api';

import house from '../../../assets/image/end.png';
import motoboy from '../../../assets/image/delivery.png';
import store from '../../../assets/image/start.png';

import '../../../assets/style/pages/delivery/inroute/index.scss';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

export const PageDeliveryInRoute = (props) => {

    const { user } = useAPP();

    const count = useRef(0);
    const mapsdata = useRef();

    let id = useRef();
    id.current = props?.history?.location?.pathname;
    if(id.current){
        id.current = id.current.substr( id.current.lastIndexOf('/') ,id.current.length);
        id.current = id.current ? id.current.replace(/\D/gi, '') : null;
    }

    const [myRoutes, setMyRoutes] = useState();
    const [map, setMap] = useState(null);
    const [center, setCenter] = useState(null)
    const [start, setStart] = useState(null)
    const [end, setEnd] = useState(null)
    const [motion, setMotion] = useState(null)
    const [routeMap, setRouteMap] = useState([]);
    const [code, setCode] = useState('');

    const [start_service, setStartService] = useState(null)
    const [end_service, setEndService] = useState(null)

    const [storeData, setStoreData] = useState();

    const routes_list = useRef();
    const position_id = useRef(0);
    const timer = useRef();
    const executeSimulate = false;

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: TOKEN_GOOGLE_MAPS
      })

    const simulate = useCallback(()=>{

        timer.current = setTimeout(()=>{
            clearTimeout(timer.current);
            const pos = position_id.current;
            position_id.current++;
            console.log(pos, routes_list.current.length);
            if(pos < routes_list.current.length){
                setMotion(routes_list.current[pos]);
                setCenter(routes_list.current[pos]);
            }
        }, 1000)

    }, [])

    useEffect(()=>{
        if(executeSimulate){
            simulate();
        }
    }, [motion])

    useEffect(()=>{

        if(myRoutes?.maps_direction){
            const temp = JSON.parse(myRoutes?.maps_direction);
            routes_list.current = temp.routes[0].overview_path;
            position_id.current = 0;
        }

        if(executeSimulate){
            simulate();
        }

    }, [myRoutes])

    const onLoad = useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const directionsCallback = async (result,status) => {

        console.log('Callback', start, end, myRoutes);

        if (status === "OK" && count.current === 0) {
          count.current++;
          console.count();
          console.log('Result router', result);
          await updateDelivery({id:myRoutes.id, maps_direction: JSON.stringify(result)});
          setRouteMap(result);
          getData();
          
        }
      };

    
      const getGeolocation = useCallback(() => {
        console.log('GEo');
        if('geolocation' in navigator){
            navigator.geolocation.watchPosition(position => {
                setMotion({lat: position.coords.latitude, lng: position.coords.longitude});;
        });
       }
    },[])

    const getData = useCallback(async()=>{

        if(id.current && user?.empresa_id){
            const data = await getMyRoute(user?.empresa_id);
            const current = data.data.filter(item => ~~item.id === ~~id.current);
            
            if(current.length === 1){
                const route =  current[0];
                const distance = JSON.parse(route.maps_distance);
                console.log(distance);
                const direction = route.maps_direction ? JSON.parse(route.maps_direction) : null;
                console.log('Directions', direction);

                mapsdata.current = direction ? true : false;
                

                setMyRoutes(current[0]);

                if(direction){
                    setCenter(direction.routes[0].legs[0].start_location)
                    setStart(direction.routes[0].legs[0].start_location)
                    setEnd(direction.routes[0].legs[0].end_location);
                    getGeolocation();
                }else{
                    setStartService(distance?.origin_addresses[0]);
                    setEndService(distance?.destination_addresses[0]);
                }


                if(mapsdata.current){
                    setRouteMap(JSON.parse(route.maps_direction));
                }

                // //setMotion({ lat: -19.8945291, lng: -44.083208 })
                // const list = [];

                // direction.routes[0].legs[0].steps.map(item =>{

                //     list.push(item.start_location);
                //     list.push(item.end_location);

                //     return item;
                // })

                //console.log({directions: list});

                //setRouteMap(direction);
            }
        }

    }, [user]);
    
    useEffect(()=>{
        getData();
    },[getData])

    return (
        <div className="page-delivery-inroute">
            <div className="main-frame">
                <h3>Rota para entrega</h3>
            </div>
            <div className="area-map">
                <div className="map">
                    {
                        isLoaded ?
                            <GoogleMap
                                center={center}
                                zoom='16'
                                mapContainerStyle={{ width: '100%', height: '100%' }}
                                options={{
                                    zoomControl: false,
                                    streetViewControl: false,
                                    mapTypeControl: false,
                                    fullscreenControl: false,
                                }}
                                onLoad={onLoad}
                                >
                                {routeMap && (<DirectionsRenderer directions={routeMap} options={{strokeColor: "#00FF00",strokeWeight: 6,strokeOpacity: 0.8, suppressMarkers: true}} />)}

                                {motion && (<Marker icon={motoboy} position={motion} />) }
                                {start && (<Marker icon={store} position={start} />)}
                                {end && (<Marker icon={house} position={end} />)}
                                {
                                    start_service && end_service && !mapsdata?.current ?
                                    <DirectionsService
                                    options={{
                                        origin: start_service,
                                        destination: end_service,
                                        travelMode: window.google.maps.TravelMode.DRIVING
                                    }}
                                    callback={directionsCallback}
                                    />
                                :
                                ''
                                }
                            </GoogleMap>
                        :
                            ''
                    }
                </div>
            </div>
            {
                myRoutes && !myRoutes?.data_cancelamento ?
                    <div className="resume">
                        <p>
                            <strong>Pedido: </strong> {myRoutes.numero}
                        </p>
                        <p>
                            <strong>Endereço: </strong> {myRoutes.endereco}
                        </p>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6">
                                <strong>Forma pagamento:</strong> {myRoutes.forma_pagamento}
                            </div>
                            <div className="p-col-12 p-md-3">
                                <strong>Total:</strong> {myRoutes.total}
                            </div>
                            <div className="p-col-12 p-md-3">
                                <strong>Troco:</strong> {myRoutes.troco}
                            </div>
                        </div>
                        <div className="p-grid p-mt-4">
                            <div className="p-col-12 p-md-3 p-md-offset-6">
                                <InputText value={code} onChange={(e)=> setCode(e.target.value)} placeholder='Informe o código' />
                            </div>
                            <div className="p-col-12 p-md-3">
                                <Button label="Finalizar entrega" />
                            </div>
                        </div>
                    </div>
                :
                    <div>
                        {
                            myRoutes && myRoutes?.data_cancelamento ?
                                <p>
                                    Pedido cancelado
                                </p>
                            :
                                ''
                        }
                    </div>
            }
        </div>
    )
}