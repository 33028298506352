import React, { useState, useEffect, useRef, useCallback, createElement} from 'react';

import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FileUpload } from 'primereact/fileupload';

import { Icon } from 'react-icons-kit';
import {bin} from 'react-icons-kit/icomoon/bin';


import { useForm, Controller } from 'react-hook-form';

import { getStore, getPaymentSystemList, updateStore, updateStoreOpen, updateStorePayment } from '../../../services/api/store';

import { getToken } from '../../../services/auth';

import { localeData } from '../../../services/utils';

import '../../../assets/style/pages/adm/store/index.scss';
import { URL_API } from '../../../services/api';

export const PageAdmStore = (props) => {

    let store = getToken();
    store = store ? atob(store) : store;
    store = store ? JSON.parse(store) : store;
    store = store ? store.empresa_id : store; 

    const refAvatar = useRef();
    const refCover = useRef();

    let isUpdate = useRef(true);


    const [isLoad] = useState(false);
    const [zipcodeLoad, setZipcodeLoad] = useState(false);
    const [storeData, setStoreData] = useState(null);
    const [paymentList, setPaymentList] = useState();

    const[previewAvatar, setPreviewAvatar] = useState(false);
    const[previewCover, setPreviewCover] = useState(false);

    const form = useForm();
    const control = form.control;
    const errors = form.formState.errors;
    const handleSubmit =  form.handleSubmit;

    const getDataAddress = async (_data) => {

        if((_data || '' ).replace(/\D/gi,'').length === 8){

            setZipcodeLoad(true);

            const url_base = `https://viacep.com.br/ws/${_data.replace(/\D/gi,'')}/json/`;

            const header = new Headers();
            header.append('Access-Control-Allow-Origin', '*');
            header.append('Access-Control-Allow-Headers','*');
            
            const params = {
                method: 'GET',
                headers: header
            }

            const addressData = await fetch(url_base, params);
            const addressLoad = await addressData.json();

            form.setValue('logradouro', addressLoad.logradouro);
            form.setValue('bairro', addressLoad.bairro);
            form.setValue('cidade', addressLoad.localidade);
            form.setValue('estado', addressLoad.uf);

            setZipcodeLoad(false);

        }
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const uploadImage = async (_data ) => {

        const header = new Headers();

        const params = {
            method: 'POST',
            header: header,
            body: _data
        }

        const result = await fetch(`${URL_API}/Files/Upload`, params);

        return result;
    }

    const getFile = async (_name) => {

        const data = btoa(JSON.stringify(_name));

        const header = new Headers();

        const params = {
            method: 'GET',
            header: header
        }

        const request = await fetch(`${URL_API}/Files/GetFile?data=${JSON.stringify({data: data})}`, params);
        const result =  await request.blob();

        return result;
    }


    const deleteFile = (_file) => {

        const requestDelete = async () => {

            const data = btoa(JSON.stringify(_file));

            const header = new Headers();
    
            const params = {
                method: 'DELETE',
                header: header
            }
    
            await fetch(`${URL_API}/Files/Delete?data=${JSON.stringify({data: data})}`, params);

            const datafile = {id: storeData.id};

            datafile[_file.field] = null;

            await updateStore(datafile);

            switch(_file.field){
                case 'avatar':
                    setPreviewAvatar(null);
                break;
                default:
                    setPreviewCover(null);
            }

            getData();

        }

        requestDelete();

    }

    const onSubmit = async (_data) => {
        //console.log(_data);
        const store_data = {
            id: '',
            nome: '',
            inscricao_municipal: '',
            inscricao_estadual: '',
            slogan: '',
            cep: '',
            logradouro: '',
            numero: '',
            complemento:'',
            bairro:'',
            cidade:'',
            estado:'',
            avatar:'', 
            capa:''
        }

        const store_temp = {};

        Object.keys(store_data).map(key => {
            store_temp[key] = _data[key];
            return key;
        });

        if(_data.avatar?.files){
            const fileAvatar = refAvatar.current.files[0]?.name?.split('.');
            store_temp.avatar = `avatar.${fileAvatar[fileAvatar.length - 1]}`;

            const formImageAvatar = new FormData();
            formImageAvatar.append('filename', 'avatar');
            formImageAvatar.append('path', storeData?.pasta);
            formImageAvatar.append('file', refAvatar.current.files[0]);

            await uploadImage(formImageAvatar);        

        }else{
            store_temp.avatar = storeData.avatar;
        }

        if(_data.capa?.files){
            const fileCapa = refCover.current.files[0]?.name?.split('.');
            store_temp.capa = `capa.${fileCapa[fileCapa.length - 1]}`;

            const formImageCapa = new FormData();
            formImageCapa.append('filename', 'capa');
            formImageCapa.append('path', storeData?.pasta);
            formImageCapa.append('file', refCover.current.files[0]);

            await uploadImage(formImageCapa);

        }else{
            store_temp.capa = storeData.capa;       
        }

        await updateStore(store_temp);

        const open_data = _data.funcionamento.map(item => {
            if(_data[`funcionamento_${item.dia_semana_nome}`]){
                item.inicio = _data[`aberto_inicio_${item.dia_semana_nome}`];
                item.fim = _data[`aberto_fim_${item.dia_semana_nome}`];
            }else{
                item.inicio = '00:00:00';
                item.fim = '00:00:00';
            }
            return item;
        });

        await updateStoreOpen(open_data);


        let payment_data = paymentList.map(item => {
            if(_data[`payment_active_${item.id}`])
                return ({empresa_id: _data.id, forma_pagamento_id: item.id});
            return null;
        });
        

        payment_data = payment_data.filter(item => item);

        await updateStorePayment(payment_data);

        getData();
    }

    const getData = useCallback(()=> {
        const getDataStore = async () => {
            const storeDataTemp =  await getStore(store);
            setStoreData(storeDataTemp.data);

            //console.log('Empresa',storeDataTemp.data);

            const keys = Object.keys(storeDataTemp.data);

            keys.map(item => {   
                form.setValue(item, storeDataTemp.data[item]);
                return item;
            });

            storeDataTemp.data?.funcionamento.map(item => {
                form.setValue(`aberto_inicio_${item.dia_semana_nome}`, item.inicio);
                form.setValue(`aberto_fim_${item.dia_semana_nome}`, item.fim);
                form.setValue(`funcionamento_${item.dia_semana_nome}`, item.inicio === item.fim ? false : true);
                return item;
            });

            const payment = await getPaymentSystemList();
            setPaymentList(payment.data);

            if(storeDataTemp.data?.avatar){
                const avatarFile = await getFile({path: storeDataTemp.data?.pasta, filename: storeDataTemp.data?.avatar});
                let blobImage = null;
                try{
                    blobImage = URL.createObjectURL(avatarFile);
                }catch(e){}
                const avatarImage = createElement('img', {src:blobImage});
                setPreviewAvatar(avatarImage);
            }


            if(storeDataTemp.data?.capa){
                const coverFile = await getFile({path: storeDataTemp.data?.pasta, filename: storeDataTemp.data?.capa});
                let blobImage = null;
                try{
                    blobImage = URL.createObjectURL(coverFile);
                }catch(e){}
                const coverImage = createElement('img', {src:blobImage});
                setPreviewCover(coverImage);
            }

        }
        getDataStore();
    }, [form, store]);

    useEffect(()=>{

        storeData?.forma_pagamento?.map(item => {
            form.setValue(`payment_active_${item.id}`, true);
            return item;
        });

    }, [paymentList, form, storeData]);


    useEffect(()=> {

        if(isUpdate.current){
            isUpdate.current = false;
            getData();
        }

    }, [getData]);

    return (
        <div className="page-adm-store">
            <div className="main-frame">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h3>
                    Gerenciar loja
                    </h3>
                    <Divider>
                        <i className="pi pi-home p-mr-2" />
                        <strong style={{fontSize:'1em'}}>
                            Dados
                        </strong>
                    </Divider>
                    <div className="p-grid p-mt-4">
                        <div className="p-col-12 p-mb-4">
                            <strong>CNPJ</strong>: {storeData?.cnpj}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="nome" control={control}  render={({ field, fieldState }) => (
                                        <InputText type="text" style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="nome">Nome</label>
                                </span>
                                {getFormErrorMessage('nome')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="razao" control={control}  render={({ field, fieldState }) => (
                                        <InputText type="text" style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="razao">Razão</label>
                                </span>
                                {getFormErrorMessage('razao')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="inscricao_municipal" control={control}  render={({ field, fieldState }) => (
                                        <InputText type="text" style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="inscricao_municipal">Inscrição municipal</label>
                                </span>
                                {getFormErrorMessage('inscricao_municipal')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="inscricao_estadual" control={control}  render={({ field, fieldState }) => (
                                        <InputText type="text" style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="inscricao_estadual">Inscrição estadual</label>
                                </span>
                                {getFormErrorMessage('inscricao_estadual')}
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="slogan" control={control}  render={({ field, fieldState }) => (
                                        <InputText type="text" style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="slogan">Slogan</label>
                                </span>
                                {getFormErrorMessage('slogan')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="taxa_entrega" control={control}  render={({ field, fieldState }) => (
                                        <InputText type="text" style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="taxa_entrega">Taxa entrega</label>
                                </span>
                                {getFormErrorMessage('taxa_entrega')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="pedido_minimo" control={control}  render={({ field, fieldState }) => (
                                        <InputText type="text" style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="pedido_minimo">Pedido mínimo</label>
                                </span>
                                {getFormErrorMessage('pedido_minimo')}
                            </div>
                        </div>
                    </div>
                    <Divider>
                        <i className="pi pi-info-circle p-mr-2" />
                        <strong style={{fontSize:'1em'}}>
                            Contato
                        </strong>
                    </Divider>
                    <div className="p-grid p-mt-4">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="telefone" control={control}  render={({ field, fieldState }) => (
                                        <InputText type="tel" style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="telefone">Telefone</label>
                                </span>
                                {getFormErrorMessage('telefone')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="celular" control={control}  render={({ field, fieldState }) => (
                                        <InputText type="tel" style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="celular">Celular</label>
                                </span>
                                {getFormErrorMessage('celular')}
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="email" control={control}  render={({ field, fieldState }) => (
                                        <InputText type="email" style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="email">E-mail</label>
                                </span>
                                {getFormErrorMessage('email')}
                            </div>
                        </div>
                    </div>
                    <Divider>
                        <i className="pi pi-map-marker p-mr-2" />
                        <strong style={{fontSize:'1em'}}>
                            Localização
                        </strong>
                    </Divider>
                    <div className="p-grid p-mt-4">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label p-input-icon-right" style={{width:'100%'}}>
                                    <Controller name="cep" control={control} render={({ field, fieldState }) => (
                                        <>
                                            {zipcodeLoad ? <i className="pi pi-spin pi-spinner" /> : '' }
                                            <InputText onInput={(e) => getDataAddress(e.target.value)} style={{width:'100%'}} id={field.name} {...field} />
                                        </>
                                    )} />
                                    <label htmlFor="cep">Cep</label>
                                </span>
                                {getFormErrorMessage('cep')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="logradouro" control={control} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="logradouro">Endereço</label>
                                </span>
                                {getFormErrorMessage('logradouro')}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid p-mt-2">
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="numero" control={control} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="numero">Número</label>
                                </span>
                                {getFormErrorMessage('numero')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="complemento" control={control} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="complemento">Complemento</label>
                                </span>
                                {getFormErrorMessage('complemento')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="bairro" control={control} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="bairro">Bairro</label>
                                </span>
                                {getFormErrorMessage('barro')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="cidade" control={control} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="cidade">Cidade</label>
                                </span>
                                {getFormErrorMessage('cidade')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="estado" control={control} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="estado">Estado</label>
                                </span>
                                {getFormErrorMessage('estado')}
                            </div>
                        </div>
                    </div>
                    <Divider>
                        <i className="pi pi-clock p-mr-2" />
                        <strong style={{fontSize:'1em'}}>
                            Funcionamento
                        </strong>
                    </Divider>
                    <DataTable value={storeData?.funcionamento} responsiveLayout="scroll">
                        <Column body={(row)=>{
                            return <div>
                                {localeData.br.dayNames[~~row.dia_semana_indice - 1]}
                            </div>
                        }} header="Dia"></Column>
                        <Column body={(row)=>{
                            return <div>
                                <div className="p-field">
                                    <span className="p-float-label">
                                        <Controller name={`aberto_inicio_${row.dia_semana_nome}`} control={control} render={({ field, fieldState }) => (
                                            <InputText type="time" style={{width:'100%'}} id={field.name} {...field} />
                                        )} />
                                        
                                    </span>
                                    {getFormErrorMessage(`aberto_inicio_${row.dia_semana_nome}`)}
                                </div>
                            </div>
                        }} header="Início"></Column>
                        <Column body={(row)=>{
                            return <div>
                                <div className="p-field">
                                    <span className="p-float-label">
                                        <Controller name={`aberto_fim_${row.dia_semana_nome}`} control={control} render={({ field, fieldState }) => (
                                            <InputText type="time" style={{width:'100%'}} id={field.name} {...field} />
                                        )} />
                                    </span>
                                    {getFormErrorMessage(`aberto_fim_${row.dia_semana_nome}`)}
                                </div>
                            </div>
                        }} header="Fim"></Column>
                        <Column style={{width:'80px', textAlign:'center'}} body={(row)=>{
                            return <div>
                            <Controller name={`funcionamento_${row.dia_semana_nome}`} control={control} render={({ field, fieldState }) => (
                                    <Checkbox onChange={e => field.onChange(e.checked)} checked={field.value} id={field.name} {...field} ></Checkbox>
                                )} />
                            </div>
                        }} header="Abre"></Column>

                    </DataTable>
                    <Divider>
                        <i className="pi pi-dollar p-mr-2" />
                        <strong style={{fontSize:'1em'}}>
                            Forma pagamento
                        </strong>
                    </Divider>
                    <DataTable value={paymentList} responsiveLayout="scroll">
                        <Column body={(row)=>{
                            return <div>
                                {row.nome}
                            </div>
                        }} header="Forma"></Column>
                        <Column style={{width:'80px', textAlign:'center'}} body={(row)=>{
                            return <div>
                            <Controller name={`payment_active_${row.id}`} control={control} render={({ field, fieldState }) => (
                                    <Checkbox onChange={e => field.onChange(e.checked)} checked={field.value} id={field.name} {...field} ></Checkbox>
                                )} />
                            </div>
                        }} header="Ativar"></Column>

                    </DataTable>
                    <Divider>
                        <i className="pi pi-palette p-mr-2" />
                        <strong style={{fontSize:'1em'}}>
                            Aparência
                        </strong>
                    </Divider>
                    <div className="p-grid p-mt-4">
                        <div className="p-col-12 p-md-6">
                            <h3>
                                Avatar
                            </h3>
                            <div className="area-preview">
                                {
                                    previewAvatar ? <div> {previewAvatar} <div onClick={()=> deleteFile({path: storeData?.pasta ,filename:storeData?.avatar, field: 'avatar'})} className="remove"> <Icon size={'1.2em'} icon={bin} /></div> </div> : ''
                                }
                            </div>
                            <Controller name="avatar" control={control} render={({ field, fieldState }) => (
                                <FileUpload ref={ref => { refAvatar.current = ref}} multiple={false}  customUpload onChange={(e)=> field.onChange(e) } onSelect={(e)=> { field.onChange(e); }} chooseLabel="Selecionar" cancelLabel="Cancelar" name="img" accept="image/*" emptyTemplate={<p className="p-m-0">Arraste e solte o arquivo aqui para upload.</p>} />
                            )} />
                        </div>
                        <div className="p-col-12 p-md-6">
                            <h3>
                                Capa
                            </h3>
                            <div className="area-preview">
                                {
                                    previewCover ? <div> {previewCover} <div onClick={()=> deleteFile({path: storeData?.pasta ,filename:storeData?.capa, field: 'capa'})} className="remove"> <Icon size={'1.2em'} icon={bin} /></div> </div> : ''
                                }
                            </div>
                            <Controller name="capa" control={control} render={({ field, fieldState }) => (
                                <FileUpload ref={ref => { refCover.current = ref}}  multiple={false} customUpload onSelect={(e)=> { field.onChange(e); }} chooseLabel="Selecionar" cancelLabel="Cancelar" name="img" accept="image/*" emptyTemplate={<p className="p-m-0">Arraste e solte o arquivo aqui para upload.</p>} />
                            )} />
                        </div>
                    </div>

                    <div className="p-grid p-mt-2 p-mb-2">
                        <div className="p-col-12 p-md-2 p-md-offset-10 area-button">
                            <Button style={{width:'100%'}} loading={isLoad} type="submit" label="Atualizar" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}