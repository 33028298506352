import React, { useState, useEffect} from 'react';

import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import {  confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';


import { getToken } from '../../../services/auth';

import { getStoreProducts, storeProductUpdate, storeProductDelete } from '../../../services/api/store';

import { ImageLoader } from '../../../components/imageloader';
import { formatToMoney } from '../../../services/utils';

import {ic_local_dining} from 'react-icons-kit/md/ic_local_dining';

import '../../../assets/style/pages/adm/product/index.scss';

export const PageAdmProductList = (props) => {

    const [productList, setProductList] = useState(null);
    const [isLoad, setIsLoad] = useState(false);

    const [globalFilter, setGlobalFilter] = useState();

    const getData = async () => {
        const token = getToken();
        const store =  JSON.parse(atob(token));

        const data = await getStoreProducts(store.empresa_id, true);
        
        setProductList(data.data);
        setIsLoad(false);
    }

    const deleteProduct = async (_data) => {
       
        confirmDialog({
            message: <p>Deseja realmente apagar este item?</p>,
            header: 'Confirmação',
            acceptLabel:'Sim',
            rejectLabel:'Não',
            acceptClassName:'p-button-danger',
            accept: async () => {
                
                setIsLoad(true);
                await storeProductDelete(_data);
                getData();
            },
            reject: () => {}
        });
    }

    const updateProduct = async (_data) => {

        setIsLoad(true);
        await storeProductUpdate(_data);
        getData();
    }

    // const createProduct = async () => {

    //     setIsLoad(true);

    //     getData();
    // }



    useEffect(()=> {
        setIsLoad(true);
        getData();
    }, []);

    return (
        <div className="page-adm-productlist">
            <div className="main-frame">
                <h3>
                    Gerenciar produtos
                </h3>
                <div className="p-grid p-mt-4">
                    <div className="p-col-12" style={{textAlign:'right'}}>
                        <Button onClick={()=> props.history.push( `/adm/product/create`) } loading={isLoad} label="Criar novo Produto"/>
                    </div>
                </div>
                <Divider />

                <DataTable 
                header={<div style={{textAlign:'right'}}><span className="p-input-icon-left"><i className="pi pi-search" /><InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Busca..." /></span></div>} globalFilter={globalFilter} 
                loading={isLoad} value={productList} paginator={productList?.length > 10} rows={10} responsiveLayout="scroll">
                <Column style={{width:'100px'}} body={(row)=>{
                    return <div>
                         <ImageLoader store={{pasta:row.pasta}} image={row.avatar} default={ic_local_dining} />
                    </div>
                }} header=""></Column>
                <Column body={(row)=>{
                    return <div>
                        {row.nome}
                    </div>
                }} sortable field="nome" header="Produto"></Column>
                <Column body={(row)=>{
                    return <div>
                        {row.nome_categoria}
                    </div>
                }} sortable field="nome_categoria" header="Categoria"></Column>
                <Column body={(row)=>{
                    return <div>
                        {formatToMoney(row.preco)}
                    </div>
                }} sortable field="preco" header="Preço"></Column>
                <Column body={(row)=>{
                    return <div>
                        {formatToMoney(row.desconto)}
                    </div>
                }} sortable field="desconto" header="Desconto"></Column>
                <Column style={{textAlign:'center'}} body={(row)=>{
                    return <div>
                        <i className={`pi ${~~row.promocional === 1 ? 'pi-check': 'pi-times'}`}></i> 
                    </div>
                }} sortable field="promocional" header="Promoção"></Column>

                <Column style={{width:'150px'}} body={(row)=>{
                    return <>
                            <Button onClick={()=>{
                                props.history.push({pathname:`/adm/product/update/${row.id}`, state:{data: row}});
                            }} icon="pi pi-pencil" className="p-button-text p-button" />
                            <Button onClick={()=> updateProduct({id: row.id, ativo: !(~~row.ativo === 1) })} icon={`pi ${~~row.ativo === 1 ? 'pi-eye' : 'pi-eye-slash' }`} className="p-button-text p-button-success" />
                            <Button onClick={()=> deleteProduct(row)} icon="pi pi-trash" className="p-button-text p-button-danger" />
                        </>
                }} header=""></Column>
            </DataTable>
            </div>
        </div>
    )
}