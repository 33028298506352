import React, {useState, useCallback, useEffect } from "react";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { getRequestsAllAdm } from '../../../services/api/user';

import '../../../assets/style/pages/adm/home/index.scss';
import { Button } from "primereact/button";

export const PageAdmHome = (props) => {

    const [requestData, setRequestData] = useState(); 

    const getStatus = (_data) => {

        const sts_pedido = ~~_data.status_pedido_id;
        const sts_producao = ~~_data.status_producao_id;
        const sts_entrega = ~~_data.status_entrega_id;

        //console.log(sts_pedido);

        let _status = null;
        let ledStatus = 'on';


        if(sts_entrega){
            switch(~~sts_entrega){
                case 0:
                    _status = 'Aguardando entregador';
                break;
                case 1:
                    _status = 'Em rota';
                break;
                case 2:
                    _status = 'Entregue';
                    ledStatus = 'close';
                break;
                default:
                    _status = sts_entrega
            }

            if(_status)
                return <span className={`status ${ledStatus}`}>{_status}</span>;
        }

        if(sts_producao){
            switch(~~sts_producao){
                case 0:
                    _status = 'Aguardando Restaurante';
                break;
                case 1:
                    _status = 'Em produção';
                break;
                case 2:
                    _status = 'Produção Finalizada';
                break;
                default:
                    _status = sts_producao
            }
            
            if(_status)
                return <span className={`status ${ledStatus}`}>{_status}</span>;
        }
        
        if(sts_pedido && ~~sts_pedido === 1){
            _status = 'Pedido Aprovado';
        }else{
            _status = 'Aguardando aprovação';
            ledStatus = 'off';
        }

        if(~~_data.cancelado === 1){
            _status = 'Cancelado';
            ledStatus = 'cancel';
        }


        return <span className={`status ${ledStatus}`}>{_status}</span>;
    }


    const getData = useCallback(async () => {
        let request = await getRequestsAllAdm();
        console.log(request.data);
        setRequestData(request.data);
    },[])

    useEffect(()=> {
        getData();
    }, [getData])

    return (
        <div className="page-adm-home">
            <div className="main-frame">
                <h3>
                    Resumo
                </h3>
                <div className="p-grid">
                    <div className="p-col-6">
                        <div className="card-box">
                            <div className="card" style={{'--backgroundCard':'var(--green-100)', '--colorCard':'var(--green-800)'}}>
                                <div className="title">Vendas hoje</div>
                                <div className="value">
                                    {
                                        requestData?.filter(item => new Date().toLocaleDateString() === new Date(item.data_emissao).toLocaleDateString()).reduce((c, n) =>{
                                            return c + (~~n.status_entrega_id === 2 ? 1 : 0)
                                        } , 0)
                                    }
                                </div>
                                <div className="icon"><i className="pi pi-thumbs-up"></i></div>
                            </div>
                            <div className="card" style={{'--backgroundCard':'var(--pink-100)', '--colorCard':'var(--pink-800)'}}>
                                <div className="title">Cancelados hoje</div>
                                <div className="value">
                                {
                                        requestData?.filter(item => new Date().toLocaleDateString() === new Date(item.data_emissao).toLocaleDateString())?.reduce((c, n) =>{
                                            return c + (~~n.cancelado === 1 ? 1 : 0)
                                        } , 0)
                                    }
                                </div>
                                <div className="icon"><i className="pi pi-thumbs-down"></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-6">
                        <h5>
                            Pedidos em aberto
                        </h5>
                        <DataTable value={requestData?.filter(item => ~~item.status_entrega_id !== 2 )} emptyMessage={'Não há pedidos em aberto'} stripedRows className="p-datatable-sm" paginator rows={10} >
                            <Column style={{width:'64px'}} field="pedido_dia" header="Ordem" />
                            <Column field="numero" header="ID" />
                            <Column body={(row)=> getStatus(row) } header="Status" />
                        </DataTable>
                    </div>
                </div>
            </div>
            
        </div>
    )
}