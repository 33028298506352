import React, { useState, useEffect, useCallback } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';

import { userRequestsAll, userRequestUpdate } from '../../../services/api/user';

import { formatToMoney } from '../../../services/utils';


import '../../../assets/style/pages/user/managerrequest/index.scss';

export const ManagerRequest = (props) => {

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);


    const getStatus = (_data) => {

        const sts_pedido = _data.sts_pedido;
        const sts_producao = _data.sts_producao;
        const sts_entrega = _data.sts_entrega;

        let _status = null;
        let icon = 'pi-spin pi-spinner';


        if(sts_entrega){
            switch(~~sts_entrega){
                case 0:
                    _status = 'Aguardando entregador';
                break;
                case 1:
                    _status = 'Em rota';
                break;
                case 2:
                    _status = 'Entregue';
                    icon = 'pi-check';
                break;
                default:
                    _status = sts_entrega
            }

            if(_status)
                return <><i className={`p-mr-2 pi ${icon}`} />{_status}</>;
        }

        if(sts_producao){
            switch(~~sts_producao){
                case 0:
                    _status = 'Aguardando Restaurante';
                break;
                case 1:
                    _status = 'Em produção';
                break;
                case 2:
                    _status = 'Produção Finalizada';
                break;
                default:
                    _status = sts_producao
            }
            
            if(_status)
                return <><i className={`p-mr-2 pi ${icon}`} />{_status}</>;
        }
        
        if(sts_pedido && ~~sts_pedido === 1){
            _status = 'Pedido Aprovado';
        }else{
            _status = 'Aguardando aprovação';
        }

        if(~~_data.cancelado === 1){
            _status = 'Cancelado';
            icon = 'pi-times';
        }


        return <><i className={`p-mr-2 pi ${icon}`} />{_status}</>;
    }


    const getData = useCallback(()=>{

        setIsLoading(true);

        const getDataBase = async () => {
            const request = await userRequestsAll(props.match.params.client);
            let _data = request.data;
            _data = _data.filter(item => ~~item.sts_entrega !== 2 );
            _data = _data.filter(item => ~~item.cancelado !== 1 );
            //console.log(_data);

            setData(_data);

            setIsLoading(false);
        }

        getDataBase();

    },[props]);

    useEffect(()=>{
        getData();

    },[getData])

    const updateRequest = async (_data) => {
        setIsLoading(true);
        await userRequestUpdate(_data);
        getData();
    }

    return(
        <div className="page-user-managerrequest">
            <div className="main-frame">
                <h3>
                    Gerenciar pedidos
                </h3>
                {
                    data ?
                        <DataTable paginator={data.length > 10} rows={10} loading={isLoading} value={data}>
                            <Column body={(_data)=>{
                                return <div>{`${new Date(_data.emissao).toLocaleDateString()} - ${new Date(_data.emissao).toLocaleTimeString()}`}</div>;
                            }

                            } header="Data"></Column>
                            <Column field="unique" header="Pedido"></Column>
                            <Column style={{width:'200px'}} body={(_data) => getStatus(_data) } header="Status"></Column>
                            <Column style={{width:'100px'}} body={(_data) => formatToMoney(parseFloat(_data.total)) } header="Preço"></Column>
                            <Column body={(_data) => {
                                return <Dropdown style={{width:'100%'}} value={~~_data.sts_pedido + 1}  options={[
                                    {label:'Aguardando aprovação', value: 1},
                                    {label:'Pedido aprovado', value: 2}
                                ]} onChange={(e) => {
                                    updateRequest({id: ~~_data.id, sts_pedido: ~~e.target.value - 1});
                                }} />
                            } } header="Pedido"></Column>
                            <Column body={(_data) => {
                                return <Dropdown disabled={~~_data.sts_pedido < 1} style={{width:'100%'}} value={~~_data.sts_producao + 1}  options={[
                                    {label:'Aguardando Restaurante', value: 1},
                                    {label:'Em produção', value: 2},
                                    {label:'Produção Finalizada', value: 3}
                                ]} onChange={(e) => {
                                    updateRequest({id: ~~_data.id, sts_producao: ~~e.target.value - 1});
                                }} />
                            } } header="Produção"></Column>
                            <Column body={(_data) => {
                                return <Dropdown disabled={~~_data.sts_producao < 2} style={{width:'100%'}} value={~~_data.sts_entrega + 1}  options={[
                                    {label:'Aguardando entregador', value: 1},
                                    {label:'Em rota', value: 2},
                                    {label:'Entregue', value: 3}
                                ]} onChange={(e) => {
                                    updateRequest({id: ~~_data.id, sts_entrega: ~~e.target.value - 1});
                                }} />
                            } } header="Entrega"></Column>
                        </DataTable>
                    :
                    <div style={{textAlign: 'center', height:'200px'}}> <ProgressSpinner style={{width: '36px', height: '36px', margin: '10px auto'}} /> </div>
                }
            </div>
        </div>
    )
}