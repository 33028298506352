import React, {useState, useEffect, useRef, useCallback} from 'react';

import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Menu } from 'primereact/menu';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Divider } from 'primereact/divider';

import { useAPP } from '../../services/provider/app';

import { AppContext } from '../../services/provider/app';

import { isAuthenticated, logout, getToken } from '../../services/auth';

import { getCartList, removeCartItem } from '../../services/shoppingcart';

import { userProfile, userProfileAdm, userAddress } from '../../services/api/user';

import { getStore } from '../../services/api/store';

import { formatToMoney } from '../../services/utils';

export const HeaderBar = (props) => {

    const token = getToken();
    const store = token ?  JSON.parse(atob(token))?.empresa_id : props.match.params.client;


    let type = getToken();
    type = type ? atob(type) : type;
    type = type ? JSON.parse(type) : type;
    type = type ? type.empresa_id : type;

    const {setVersion, user, setUser, company, setCompany} = useAPP();
    const [showMenu, setShowMenu] = useState(false);
    const [currentLocation, setCurrentLocation] = useState('');
    const [distance, setDistance] = useState(0);
    const [address, setAddress] = useState();

    const menuCart = useRef();
    const menuLocation = useRef();
    const menuUser = useRef();
    const currentLocationCords = useRef();

    const clientID = store || (!isNaN(props.match.params.client) ? props.match.params.client : store );

    //console.log('show user', user)
    

    let menu = [
        {label: 'Loja', icon:'pi pi-external-link', command:()=> {props.history.push({pathname:`${!isNaN(store) ? '/' : '/' }${clientID}`}); closeAllMenus(); }},
        {separator:true}
    ];
    
    let userMenu = [];
    
    if(isAuthenticated()){
        
        
        if(user?.tipo?.filter(item => /cliente/gi.test(item.nome)).length > 0){
            userMenu = [
                {label: 'Carrinho de compras', icon:'pi pi-shopping-cart', command:()=>{ props.history.push(`/${props.match.params.client}/user/cart`); closeAllMenus();}},
                {label: 'Perfil', icon:'pi pi-user', command:()=>{ props.history.push(`/${props.match.params.client}/user`); closeAllMenus();}},
                {label: 'Endereços', icon:'pi pi-map-marker', command:()=>{ props.history.push(`/${props.match.params.client}/user/address`); closeAllMenus();}},
                {label: 'Pedidos', icon:'pi pi-list', command:()=>{props.history.push(`/${props.match.params.client}/user/requests`); closeAllMenus();}},
                {label: 'Formas de pagamento', icon:'pi pi-credit-card', command:()=>{props.history.push(`/${props.match.params.client}/user/payment`); closeAllMenus();}},
                
            ];
        }
        if(user?.tipo?.filter(item => /atendente|gerente/gi.test(item.nome)).length > 0){
            userMenu.push({label: 'Home', icon:'pi pi-chart-bar', command:()=>{ props.history.push(`/adm/home`); closeAllMenus();}});
        }
        
        if(user?.tipo?.filter(item => /atendente/gi.test(item.nome)).length > 0){
            userMenu.push({label: 'Gerenciar produtos', icon:'pi pi-table', command:()=>{ props.history.push(`/adm/product`); closeAllMenus();}});
            userMenu.push({label: 'Gerenciar pedidos', icon:'pi pi-desktop', command:()=>{ props.history.push(`/adm/managerrequest`); closeAllMenus();}});
        }

        if(user?.tipo?.filter(item => /gerente/gi.test(item.nome)).length > 0){

            userMenu.push({label: 'Gerenciar empresa', icon:'pi pi-home', command:()=>{ props.history.push(`/adm/store`); closeAllMenus();}});
            userMenu.push({label: 'Gerenciar categorias', icon:'pi pi-list', command:()=>{ props.history.push(`/adm/productcategory`); closeAllMenus();}});
            userMenu.push({label: 'Gerenciar produtos', icon:'pi pi-table', command:()=>{ props.history.push(`/adm/product`); closeAllMenus();}});
            userMenu.push({label: 'Gerenciar pedidos', icon:'pi pi-desktop', command:()=>{ props.history.push(`/adm/managerrequest`); closeAllMenus();}});
            userMenu.push({label: 'Disponibilidade dos itens', icon:'pi pi-th-large', command:()=>{ props.history.push(`/adm/availableitem`); closeAllMenus();}});
            userMenu.push({label: 'Gerenciar funcionários', icon:'pi pi-users', command:()=>{ props.history.push(`/adm/employee`); closeAllMenus();}});
            userMenu.push({label: 'Gerenciar delivery', icon:'pi pi-directions', command:()=>{ props.history.push(`/adm/delivery`); closeAllMenus();}});
        }

        if(user?.tipo?.filter(item => /entregador/gi.test(item.nome)).length > 0){
            userMenu.push({label: 'Gerenciar delivery', icon:'pi pi-directions', command:()=>{ props.history.push(`/adm/delivery`); closeAllMenus();}});
            /*userMenu.splice(2,0, { label: 'Entregador',
            items:[
                {label: 'Gerenciar pedidos', icon:'pi pi-desktop', command:()=>{ props.history.push(`/adm/managerrequest`); closeAllMenus();}},
                {label: 'Disponibilidade dos itens', icon:'pi pi-th-large', command:()=>{ props.history.push(`/adm/availableitem`); closeAllMenus();}},
            ] });
            userMenu.splice(3,0, {separator:true});*/
        }

        if(user?.tipo?.filter(item => /master/gi.test(item.nome)).length > 0){
            /*userMenu.splice(2,0, { label: 'Master',
            items:[
                {label: 'Gerenciar pedidos', icon:'pi pi-desktop', command:()=>{ props.history.push(`/adm/managerrequest`); closeAllMenus();}},
                {label: 'Disponibilidade dos itens', icon:'pi pi-th-large', command:()=>{ props.history.push(`/adm/availableitem`); closeAllMenus();}},
            ] });*/
           // userMenu.splice(3,0, {separator:true});
            userMenu.push({label: 'Gerenciar empresa', icon:'pi pi-home', command:()=>{ props.history.push(`/adm/store`); closeAllMenus();}});
            userMenu.push({label: 'Gerenciar categorias', icon:'pi pi-list', command:()=>{ props.history.push(`/adm/productcategory`); closeAllMenus();}});
            userMenu.push({label: 'Gerenciar produtos', icon:'pi pi-table', command:()=>{ props.history.push(`/adm/product`); closeAllMenus();}});
            userMenu.push({label: 'Gerenciar pedidos', icon:'pi pi-desktop', command:()=>{ props.history.push(`/adm/managerrequest`); closeAllMenus();}});
            userMenu.push({label: 'Disponibilidade dos itens', icon:'pi pi-th-large', command:()=>{ props.history.push(`/adm/availableitem`); closeAllMenus();}});
            userMenu.push({label: 'Gerenciar funcionários', icon:'pi pi-users', command:()=>{ props.history.push(`/adm/employee`); closeAllMenus();}});
            userMenu.push({label: 'Gerenciar delivery', icon:'pi pi-directions', command:()=>{ props.history.push(`/adm/delivery`); closeAllMenus();}});
        }

       // userMenu.splice(userMenu.length,0, {separator:true});

        userMenu.push({label: 'Sair', icon:'pi pi-power-off', command:()=>{ closeAllMenus(); logout(); setVersion(new Date().getTime());  props.history.push(`/${props.match.params.client}`);}});

    }else{
        userMenu = [
            {
                label: 'Usuário', 
                items:[
                    {label: 'Login', icon:'pi pi-user', command:()=>{props.history.push(`/${props.match.params.client}/user/login`); closeAllMenus();}},
                    {label: 'Cadastro', icon:'pi pi-user-plus', command:()=>{ props.history.push(`/${props.match.params.client}/user/sigup`); closeAllMenus();}},
                ]
            }
        ];
    }
    
    menu.push(...userMenu);


    let menutemp = [];

   // menu = [...new Set(menu)];

   menu.map(item => {
       if(menutemp.filter(el => el.label === item.label).length === 0){
           menutemp.push(item);
       }
       return item;
   })

   menu = menutemp;

   menu.splice(menu.length -1 ,0, {separator:true});


    const getTotal = () => {
        const items = getCartList();
        
        const value = items.reduce((_c, _n) => {

            let itens = (_n.combo || []);
            itens = itens.filter(el => ~~el.quantity > 0);
            const valueItens = itens.reduce((_a, _i)=> {
                return _a + (parseFloat(_i.price) * ~~_i.quantity)
            }, 0);

            

            return _c + ((parseFloat(_n.price) * ~~_n.quantity) + (valueItens * ~~_n.quantity)  - (parseFloat(_n.descont) * ~~_n.quantity));
        } , 0);

        return value;
    }

    const getTotalItem = (_data) => {
        const items = _data;

        let itens = (items.combo || []);
            itens = itens.filter(el => ~~el.quantity > 0);
            const valueItens = itens.reduce((_a, _i)=> {
                return _a + (parseFloat(_i.price) * ~~_i.quantity)
            }, 0);
        
        return ((parseFloat(items.price) * ~~items.quantity) + (valueItens * ~~items.quantity)  - (parseFloat(items.descont) * ~~items.quantity));
    }

    const calcDistance = useCallback(() => {
        var a = Math.PI,
        n = currentLocationCords.current.latitude * a/180,
        c = currentLocationCords.current.longitude * a/180,
        r = company?.detalhe?.latitude * a/180,
        i = company?.detalhe?.longitude * a/180;
        
        return (6371 * Math.acos(Math.cos(n) * Math.cos(c) * Math.cos(r) * Math.cos(i) + Math.cos(n) * Math.sin(c) * Math.cos(r) * Math.sin(i) + Math.sin(n) * Math.sin(r)) * 1.0025).toFixed(2);
        
    },[company])


    const getGeolocation = useCallback(() => {
        console.log('#user', user);
        if('geolocation' in navigator){
        //     navigator.geolocation.watchPosition(position => {
        //        ////console.log(position.coords.latitude, position.coords.longitude);
        //        currentLocationCords.current = position.coords;

        //        const getData = async () => {
        //             const data = await fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=pt`);
        //             let cords = null;
        //             try{
        //                 cords = await data.json();
        //             }catch(e){}
        //             let local = null;

        //             if(cords)
        //                 local = `${cords.locality}, ${cords.city} - ${cords.principalSubdivision}`;

        //             setCurrentLocation(local);

        //             let d = calcDistance();
        //             d = isNaN(d) ? ~~d : d;
        //             setDistance(d);

        //         }

        //        getData();

        //    });
   
       }
    },[calcDistance])


    const getAddressActive = useCallback(async () => {

        const data =  await userAddress();
        console.log('Address', data.data);
        setAddress(data.data);

        let current = null;
        if(data?.data){
            current = data.data.filter(item => ~~item.iscurrent === 1)[0];
        }

        if(current){
            setCurrentLocation(`${current.titulo} - ${current.logradouro} ${current.numero} - ${current.bairro} - ${current.cidade} ${current.estado}`);
        }


    }, [])

    useEffect(()=> {

        getAddressActive();

    }, [getAddressActive]);
    
    useEffect(()=>{

        getGeolocation();

    },[getGeolocation])


    useEffect(()=>{


        const getData = async () => {
            const _user = await (type ?  userProfileAdm() :  userProfile());
            setUser(_user.data);

            //console.log(_user.data);

            if(!company && props.match.params.client){
                const com = await getStore(props.match.params.client);
                setCompany(com.data);
            }

            getGeolocation();

            
            
        }
        
        if(!user){
            getData();
        }else{
            getGeolocation();
        }
        

        setShowMenu(false);

    }, [props, setUser, user, company, setCompany, getGeolocation, type]);

    const closeAllMenus = () => {
        menuUser?.current?.hide(); 
        menuCart?.current?.hide();
        menuLocation?.current?.hide();
    }

    return (
        <div className="area-header">
            <AppContext.Consumer>
               {data =>(
                    <>
                        <Toolbar left={()=>
                            <>
                                <Button onClick={()=> setShowMenu(true)} icon="pi pi-bars" className="p-button-text p-button-plain p-button-rounded" />
                                <Sidebar
                                    icons={()=>
                                        <div style={{width:'100%'}} className="p-d-flex">
                                            <h3>
                                                Pedidos Web
                                            </h3>
                                        </div>
                                    }
                                    visible={showMenu} onHide={() => setShowMenu(false)}>
                                    <Menu  key={`toolbar-rigth-menu-${data.version}`} style={{width:'100%', border:'0'}} model={menu} />
                                </Sidebar>
                            </>
                         } 
                        right={()=>
                        <div>
                            {
                                isAuthenticated() ?
                                    <>
                                        {
                                            !type ?
                                            <>
                                                <Button onClick={(e) =>{ closeAllMenus(); menuUser.current.toggle(e);}} label={user?.nome.split(' ')[0]} icon="pi pi-arrow-down" iconPos="right" className="p-button-text p-button-sm p-button p-mr-2" />
                                                <OverlayPanel ref={menuUser}>
                                                    <Menu  style={{width:'100%', border:'0', fontSize:'0.8em'}} model={[...userMenu]} />
                                                </OverlayPanel>
                                            </>
                                            :
                                            <Button label={user?.nome.split(' ')[0]} icon="pi pi-user" iconPos="right" className="p-button-text p-button-sm p-button p-mr-2" />
                                        }
                                    </>
                                :
                                    <>
                                        <Button onClick={(e) => props.history.push(`/${props.match.params.client}/user/login`)} label="Login" className="p-button-text p-button-sm p-button p-mr-2" />
                                        <Button onClick={(e) => props.history.push(`/${props.match.params.client}/user/sigup`)} label="Cadastro" className="p-button-text p-button-sm p-button" />
                                    </>
                            }
                            {
                                !type ?
                            <>
                            <Button onClick={(e) =>{ closeAllMenus(); menuCart.current.toggle(e);}} icon="pi pi-shopping-cart" className={`${ getCartList().length === 0 ?  'p-button-text' : ''} p-button-danger p-button-rounded`} />
                            <OverlayPanel ref={menuCart}>
                                {
                                    getCartList().length > 0 ?
                                        <div key={`toolbar-rigth-cart-${data.version}`}>
                                            <Divider >
                                                <strong>Itens adicionados</strong>
                                            </Divider>
                                            <div style={{minWidth:'280px', width:'100%', maxHeight:'50vh', overflowY:'auto', overflowX:'hidden', fontSize:'0.8em'}}>
                                                {
                                                    getCartList().map((item, id) => {
                                                        return <div key={`cart-list-${id}`} className="p-grid p-m-1 p-ai-center" >
                                                            <div className="p-col-fixed" style={{width:'40px', textAlign:'center'}}>
                                                                <span style={{borderRadius: '50%', width:'22px', height:'22px', minWidth:'22px', minHeight:'22px', backgroundColor:'var(--green-100)', display:'flex', alignItems:'center', justifyContent:'center', fontSize:'0.7em'}}>
                                                                    {id + 1}
                                                                </span>
                                                            </div>
                                                            <div className="p-col">
                                                                {item.quantity} X {item.name}
                                                            </div>
                                                            <div className="p-col-fixed" style={{width:'90px', textAlign:'right'}}>
                                                                {formatToMoney(getTotalItem(item))}
                                                            </div>
                                                            <div className="p-col-fixed" style={{width:'40px'}}>
                                                                <Button onClick={(e) => { removeCartItem(item.id); setVersion(new Date().getTime());}} icon="pi pi-trash" className="p-button-text p-button-danger p-button-rounded" />
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                            <Divider />
                                            <div className="p-d-flex p-m-2 p-jc-end">
                                                <strong>Total: {formatToMoney(getTotal())}</strong>
                                            </div>
                    
                                            <div className="p-d-flex p-m-2 p-jc-center">
                                                <Button onClick={(e) => { props.history.push(`/${props.match.params.client}/user/cart`); closeAllMenus(); }} label="Finalizar" className="p-button-danger p-button-sm" />
                                            </div>
                                        </div>
                                    :
                                    <p>Não há itens no carrinho.</p>
                                }
                    
                            </OverlayPanel>
                            <Button onClick={(e) =>{ closeAllMenus(); menuLocation.current.toggle(e)}} icon="pi pi-map-marker" className="p-button-text p-button-warning p-button-rounded" />
                            <OverlayPanel ref={menuLocation}>
                                <div><strong>Endereço de entrega:</strong></div>
                                <div>
                                    {currentLocation}
                                </div>
                                <Divider>
                                    <i className="pi pi-map p-mr-2" ></i>
                                    <strong>Distância do estabelecimento</strong>
                                </Divider>
                                <p>
                                    {parseFloat(distance)} KM
                                </p>
                            </OverlayPanel>
                            </>
                            :
                            ''
                            }
                        </div>
                        } />
                    </>
               )}
            </AppContext.Consumer>
        </div>
    )
}