import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react';

import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';

import { useForm, Controller } from 'react-hook-form';
import { getStoreCategories, getStoreProductById,  storeComboDelete, storeProductDelete, storeProductUpdate , getStore} from '../../../../services/api/store';

import { ImageLoader } from '../../../../components/imageloader';

import {ic_local_dining} from 'react-icons-kit/md/ic_local_dining';

import { getToken } from '../../../../services/auth';

import { URL_API } from '../../../../services/api';

import { Icon } from 'react-icons-kit';
import {bin} from 'react-icons-kit/icomoon/bin';

import '../../../../assets/style/pages/adm/product/index.scss';
import { formatToMoney } from '../../../../services/utils';

export const PageAdmProductUpdate = (props) => {


    const [store, setStore] = useState();
    const [categories, setCategories] = useState();
    const [combo, setCombo] = useState([]);
    const [product, setProduct] = useState();

    const [categoryCreate, setCategoryCreate] = useState(null);
    const [isLoad] = useState(false);

    const [expandedRows, setExpandedRows] = useState(null);

    const [categoryEdit, setCategoryEdit] = useState();

    const refAvatar = useRef();

    const toast = useRef(null);

    let isNoLoad = useRef(true);

    const verifyComplete = (e) => {
        if(e?.onCallback){
            e.onCallback();
        }
    }

    const defaultForm = useMemo(()=> ({
        desconto: 0,
        detalhes: '',
        nome: '',
        preco: 0,
        produto_categoria_id: 0,
        promocional: false
    }),[]);

    const form = useForm({defaultForm});

    const control = form.control;
    const errors = form.formState.errors;
    const reset  = form.reset;
    const handleSubmit =  form.handleSubmit;

    const formProduct = useForm({
        nome:'',
        preco: 0,
        desconto: 0
    });
    
    const controlProduct = formProduct.control;
    //const errorsProduct = formProduct.formState.errors;
    const resetProduct = formProduct.reset;
    //const handleSubmitProduct =  formProduct.handleSubmit;


    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const uploadImage = async (_data ) => {

        const header = new Headers();

        const params = {
            method: 'POST',
            header: header,
            body: _data
        }

        const result = await fetch(`${URL_API}/Files/Upload`, params);

        return result;
    }


    const deleteFile = (_file) => {

        const requestDelete = async () => {

            const storeTemp = await getStore(product.empresa_id);    

            const data = btoa(JSON.stringify({path: storeTemp?.data?.pasta , ..._file}));

            const header = new Headers();
    
            const params = {
                method: 'DELETE',
                header: header
            }
    
            await fetch(`${URL_API}/Files/Delete?data=${JSON.stringify({data: data})}`, params);

            const datafile = {id: product.id, avatar: null};

            await storeProductUpdate(datafile);

            getProduct();

        }

        requestDelete();

    }

    const createCategory = () => {
        const temp = [...combo];
        temp.push({nome: categoryCreate, combo: []});

        setCombo(temp);
    }

    const removeCategory = async (_data) => {
        const temp = combo.filter(item => item !== _data);

        ////console.log('REmove', _data);

        await storeComboDelete({id: _data.combo_id});

        setCombo(temp);
    }

    const createProduct = (_data) => {
        const temp = [...combo];
        let id = null;

        temp.map((item, _id) => {
            if(item === _data)
                id = _id

            return item;
        });

        //console.log('#', id);

        const values = formProduct.getValues();

        const productData = {
            combo_produto_nome:'',
            combo_produto_preco:'',
            combo_produto_desconto:'',
            combo_produto_descricao:''
        }

        Object.keys(productData).map(item => {
            productData[item] = values[`${item}__${_data.nome}`];
            return item;
        })

        if(!isNaN(id))
            temp[id].combo.push({...productData});

        const tempReset  = {...formProduct.getValues()};

        ////console.log('#',tempReset);

        Object.keys(tempReset).map(item => {
            tempReset[item] = '';
            return item;
        })

       // //console.log('@', tempReset);


        resetProduct(tempReset);

        setCombo(temp);
    }

    const removeProduct = async (_data, _product) => {
        const temp = [...combo];
        let id = null;

        temp.map((item, _id) => {
            if(item === _data)
                id = _id

            return item;
        });

        if(!isNaN(id))
            temp[id].combo = temp[id].combo.filter(item => item !== _product);

            //console.log(_product);

        await storeProductDelete({id: _product.combo_produto_id});

        setCombo(temp);
    }

    const onSubmit = async (data) => {
        //console.log(data);

        const token = getToken();
        const store =  JSON.parse(atob(token));

        let _data = {};
        Object.keys(defaultForm).map(item => {
            _data[item] = product[item];
            return item;

        });

        _data = {..._data, id: product.id, ...data};
        _data.promocional = data.promocional ? 1 : 0;
        _data.empresa_id = store.empresa_id;


        const storeTemp = await getStore(store.empresa_id);        

        if(data.avatar?.files){
            const fileAvatar = refAvatar.current.files[0]?.name?.split('.');
            _data.avatar = `${product.id}.${fileAvatar[fileAvatar.length - 1]}`;

            const formImageAvatar = new FormData();
            formImageAvatar.append('filename', product.id);
            formImageAvatar.append('path', storeTemp.data?.pasta);
            formImageAvatar.append('file', refAvatar.current.files[0]);

            await uploadImage(formImageAvatar);        

        }else{
            _data.avatar = data.avatar;
        }


         // Product
       const result = await storeProductUpdate(_data);

       if(result.data){
            toast.current.show({severity: 'success', summary: 'Sucesso', detail: 'Produto atualizado.', onCallback:()=>props.history.push({pathname: `/adm/product`})});
        }else{
            toast.current.show({severity: 'error', summary: 'Erro', detail: 'Produto não atualizado. Tente novamente.'});
        }


        // Product
      //  const productId = await storeProductUpdate({...data, avatar: data.avatar ? 'avatar' : ''});
       // //console.log('Produto novo', productId);


      /* let comboIndex = 0;

        const comboCreate = async () => {

            if(comboIndex < combo.length){

                const product_combo = {
                    produto_id: product.id,
                    nome: combo[comboIndex].nome,
                    minimo: 0,
                    maximo: 10,
                    obrigatorio: 0
                }

                const comboID = await storeComboCreate(product_combo);

                const productsCombo = combo[comboIndex].combo.map(item => {
                    const productComboItem = {
                        empresa_id:  store.empresa_id,
                        produto_categoria_id: null,
                        nome: item.combo_produto_nome,
                        detalhes: item.combo_produto_descricao,
                        preco: item.combo_produto_preco,
                        desconto: item.combo_produto_desconto
                    }

                    return storeProductCreate(productComboItem);
                });

                const productslistids = await Promise.all(productsCombo);

                const comboitem = productslistids.map(prod => {
                    const ci = {
                        produto_combo_id: comboID.data,
                        produto_id: prod.data
                    }
                    return storeComboItemCreate(ci);
                })

                
                await Promise.all(comboitem);

                comboIndex++;

                return await comboCreate();

            }else{
                return true;
            }

        }


        await comboCreate();*/

      /* //console.log(product);
       //console.log(combo);*/

        //getProduct();
    }



    const rowExpansionTemplate = (_data) => {
        

        return (<>
                    <h3>Criar produto combo - ({_data.nome}) </h3>
                    <div className="p-grid p-mt-4">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name={`combo_produto_nome__${_data.nome}`}  control={controlProduct}  render={({ field, fieldState }) => (
                                        <InputText type="text" style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor={`combo_produto_nome__${_data.nome}`}>Nome</label>
                                </span>
                                {getFormErrorMessage(`combo_produto_nome__${_data.nome}`)}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name={`combo_produto_preco__${_data.nome}`} control={controlProduct}  render={({ field, fieldState }) => (
                                        <InputText  value={field.value} style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor={`combo_produto_preco__${_data.nome}`}>Preço</label>
                                </span>
                                {getFormErrorMessage(`combo_produto_preco__${_data.nome}`)}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name={`combo_produto_desconto__${_data.nome}`} defaultValue="0" control={controlProduct}  render={({ field, fieldState }) => (
                                        <InputText type="text" style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor={`combo_produto_desconto__${_data.nome}`}>Desconto</label>
                                </span>
                                {getFormErrorMessage(`combo_produto_desconto__${_data.nome}`)}
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name={`combo_produto_detalhes__${_data.nome}`} control={controlProduct}  render={({ field, fieldState }) => (
                                        <InputTextarea autoResize rows={5} style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor={`combo_produto_detalhes__${_data.nome}`}>Detalhes</label>
                                </span>
                                {getFormErrorMessage(`combo_produto_detalhes__${_data.nome}`)}
                            </div>
                        </div>
                        <div className="p-col-12" style={{textAlign:'right'}} >
                            <Button onClick={(e) => { e.preventDefault(); createProduct(_data); }} label={`Salvar produto combo (${_data.nome})`} className="p-button p-mb-4" />
                        </div>
                    </div>
                <DataTable editMode="row" dataKey="id" value={_data.combo.filter(el => el.combo_produto_id)} 
                    onRowEditInit={(e)=> {
                        //console.log(e);
                        setCategoryEdit({...e.data})
                    }}
                    onRowEditSave={(e)=> {
                        
                        let temp = combo.filter(item => item);

                        temp = temp.map(it => {
                            if(~~it.combo_id === ~~categoryEdit.combo_id){
                                const temp_combo = it.combo.map(pit => {
                                    if(~~pit.combo_produto_id === ~~categoryEdit.combo_produto_id)
                                        return categoryEdit;
                                    
                                    return pit;
                                });

                                return {...it, combo: temp_combo};
                            }
                            return it;
                        })
                            
                        setCombo(temp);
                        setCategoryEdit('')
                    }}
                    onRowEditCancel={(e)=> {
                        //console.log(e);
                    }}
               >
                    <Column editor={(options) => textEditor(options)}  field="combo_produto_nome" header="Produto"></Column>
                    <Column editor={(options) => textEditor(options)} body={(row)=>{
                        return formatToMoney(row.combo_produto_preco);
                    }} field="combo_produto_preco" header="Preço"></Column>
                    <Column editor={(options) => textEditor(options)} body={(row)=>{
                        return formatToMoney(row.combo_produto_desconto);
                    }} field="combo_produto_desconto" header="Desconto"></Column>
                    <Column editor={(options) => textEditorDetails(options)} body={(row)=>{
                        return row.combo_produto_detalhes;
                    }} field="combo_produto_detalhes" header="Detalhes"></Column>
                    <Column rowEditor headerStyle={{ width: '120px' }} bodyStyle={{ textAlign: 'center' }}></Column>
                    <Column style={{width:'80px'}} body={(row)=>{
                        return <>
                            <Button onClick={(e)=>{ e.preventDefault(); removeProduct(_data, row); }} icon="pi pi-times" className="p-button-danger" />
                        </>
                    }} header=""></Column>
                </DataTable>  
            </>
        )
    }

    const textEditor = (options) => {
        return <InputText style={{width: '98%'}} type="text" value={categoryEdit[options.field]} onChange={(e) => {
            const p = {...categoryEdit};
            p[options.field] = e.target.value;
            setCategoryEdit(p);
        }} />;
    }

    const textEditorDetails = (options) => {
        return <InputTextarea style={{width: '98%'}} value={categoryEdit[options.field]} onChange={(e) => {
            const p = {...categoryEdit};
            p[options.field] = e.target.value;
            setCategoryEdit(p);
        }} />;
    }

    const textEditorCategory = (options) => {
        //console.log(options)
        return <InputText type="text" value={categoryEdit?.nome} onChange={(e) =>{ setCategoryEdit({...categoryEdit, nome: e.target.value}) }} />;
    }


    const getProduct = useCallback(() =>{

        const getProductData = async () => {
            const token = getToken();
            const store =  JSON.parse(atob(token));
    
            let productData = await getStoreProductById(store.empresa_id, props.history.location.state.data.id);
            productData = productData.data[0];
    
            setProduct(productData);
    
            //console.log( props);
            //console.log('##', productData);
    
            const comboTemp = [];
    
            let comboNames = productData.combo?.map(item =>{
                return item.combo_id;
            });
    
            comboNames = [...new Set(comboNames)];
    
            comboNames.map(item =>{
                const cb =  productData.combo?.filter(it => ~~item === ~~it.combo_id)[0];
                comboTemp.push({
                    nome: cb.combo_nome, 
                    combo_id: item,
                    combo: ( productData.combo || []).map(el => {
                        if(~~item === ~~el.combo_id){
                            return {
                                combo_id: item,
                                combo_produto_id: el.id,
                                combo_produto_nome: el.nome,
                                combo_produto_preco: el.preco,
                                combo_produto_desconto: el.desconto,
                                combo_produto_descricao: el.detalhes
                            };
                        }
    
                        return null;
                    }).filter(el => el ) 
                });
                return item;
            });
    
            setCombo(comboTemp);
    
            console.log('#', control.fieldsRef.current);
    
            Object.keys( control.fieldsRef.current).map(item => {
    
                form.setValue(item, productData[item]);
    
    
                return item;
            })
            
    
            form.setValue('promocional', ~~productData.promocional === 1);
        }

        getProductData();

    },[control, form, props])

    useEffect(()=>{
       // //console.log(combo);
        setCategoryCreate('');
    },[combo])

    useEffect(()=> {
        const getDataProduct = async () => {
            const token = getToken();
            const storeData =  JSON.parse(atob(token));
            
            const storeTemp = await getStore(storeData.empresa_id);
            setStore(storeTemp.data);

            const data = await getStoreCategories(storeData.empresa_id);
            const categories_data = data.data.map(item => ({label: item.nome, value: item.id}) );
            setCategories(categories_data);


        }

        reset(defaultForm);
        getDataProduct();

    }, [defaultForm, reset])

    useEffect(()=>{

        if(categories && isNoLoad.current){
            //console.log('Carrega produto');
            isNoLoad.current = false;
            getProduct();
            
        }
        
    }, [categories, getProduct]);

    return(
        <div className="page-adm-productcreate">
            <div className="main-frame">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h3>
                        Editar produto
                    </h3>
                    <Divider>
                        <strong style={{fontSize:'1em'}}>
                            Dados
                        </strong>
                    </Divider>
                    <div className="p-grid p-mt-4">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="nome" control={control}  render={({ field, fieldState }) => (
                                        <InputText type="text" value={field.value} style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="nome">Nome</label>
                                </span>
                                {getFormErrorMessage('nome')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="produto_categoria_id" control={control}  render={({ field, fieldState }) => (
                                        <Dropdown options={categories} value={field.value} onChange={(e)=> field.onChange(e.value) } style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="produto_categoria_id">Categoria</label>
                                </span>
                                {getFormErrorMessage('produto_categoria_id')}
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="detalhes" control={control}  render={({ field, fieldState }) => (
                                        <InputTextarea autoResize rows={5} value={field.value} style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="detalhes">Detalhes</label>
                                </span>
                                {getFormErrorMessage('detalhes')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="preco" control={control}   render={({ field, fieldState }) => (
                                        <InputText value={field.value}  onChange={(e)=> field.onChange(e.value) } style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="preco">Preço</label>
                                </span>
                                {getFormErrorMessage('preco')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="desconto" control={control}  render={({ field, fieldState }) => (
                                        <InputText  value={field.value} onChange={(e)=> field.onChange(e.value) } style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="desconto">Desconto</label>
                                </span>
                                {getFormErrorMessage('desconto')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Controller name="promocional" control={control}  render={({ field, fieldState }) => (
                                    <Checkbox checked={field.value} value={field.value} onChange={(e)=>{field.onChange(e.checked)}} id={field.name} {...field} />
                                )} />
                                <label className="p-checkbox-label p-ml-2" htmlFor="promocional">Este item é promocional?</label>
                        </div>
                        <div className="p-col-12 p-md-12">
                            <h3>
                                Imagem produto
                            </h3>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <div style={{
                                display:'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                height: '100%',
                                border:'1px solid #ccc',
                                borderRadius: '5px',
                                position: 'relative'

                            }} className='preview-image' >
                                <ImageLoader store={store} image={product?.avatar} default={ic_local_dining} /> 
                                {
                                    product?.avatar ? <div onClick={()=> deleteFile({filename: product?.avatar})} className="remove"> <Icon size={'1.2em'} icon={bin} /></div> : ''
                                }
                            </div>
                        </div>
                        <div className="p-col-12 p-md-10">
                            <Controller name="avatar" control={control} render={({ field, fieldState }) => (
                                <FileUpload ref={ref => { refAvatar.current = ref}} customUpload onSelect={(e)=> { field.onChange(e); }} chooseLabel="Selecionar" cancelLabel="Cancelar" name="img" accept="image/*" emptyTemplate={<p className="p-m-0">Arraste e solte o arquivo aqui para upload.</p>} />
                            )} />
                        </div>
                    </div>
                    <Divider>
                        <strong style={{fontSize:'1em'}}>
                            Combos
                        </strong>
                    </Divider>
                    <div className="p-grid p-mt-4 p-mb-4">
                        <div className="p-col-12">
                            <div className="p-inputgroup">
                                <InputText value={categoryCreate} onChange={(e)=> setCategoryCreate(e.target.value)} placeholder="Nome categoria"/>
                                <Button onClick={(e)=>{ e.preventDefault(); createCategory(); } } loading={isLoad} label="Criar nova categoria"/>
                            </div>
                        </div>
                    </div>
                    <DataTable value={combo}  
                        rowExpansionTemplate={rowExpansionTemplate}
                        dataKey="id"
                        expandedRows={expandedRows} 
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        editMode="row"
                        editingRows={(e)=>{
                            //console.log('###', e);
                        }}
                        onRowEditInit={(e)=> {
                            setCategoryEdit({...e.data})
                        }}
                        onRowEditSave={(e)=> {
                            let temp = combo.filter(item => item);
                            temp = temp.map(it => {
                                if(~~it.combo_id === ~~categoryEdit.combo_id)
                                    return categoryEdit;
                                return it;
                            })
                            
                            setCombo(temp);
                            setCategoryEdit('')
                        }}
                        onRowEditCancel={(e)=> {
                            setCategoryEdit('')
                        }}
                        
                    >
                        <Column expander style={{ width: '3em' }} />
                        <Column body={(row)=> <strong>{row.nome}</strong>} editor={(options) => textEditorCategory(options)} field="nome" header="Categoria"></Column>
                        <Column header='Edição' rowEditor headerStyle={{ width: '120px' }} bodyStyle={{ textAlign: 'center' }}></Column>
                        <Column style={{width:'100px'}} body={(row)=>{
                            return <>
                                <Button onClick={(e)=>{ e.preventDefault(); removeCategory(row);}} icon="pi pi-times" className="p-button-danger" />
                            </>
                        }} header="Excluir"></Column>
                    </DataTable>
                    <Divider className="p-mb-4 p-mt-4" />
                    <div className="p-d-flex p-mt-2 p-jc-end p-mt-7">
                        <Button onClick={()=> ( props?.location?.state?.callback ? props.history.push(props.location.state.callback) : props.history.push(`/adm/product`)) } label="Cancelar" className="p-button-danger p-mr-2" style={{width:'100px'}} />
                        <Button type="submit" loading={isLoad} label="Atualizar" className="p-button" style={{width:'100px'}} />
                    </div>''
                </form>
            </div>
            <Toast ref={toast} position="bottom-center" onHide={(e)=> verifyComplete(e)} onRemove={(e)=> verifyComplete(e)} />
        </div>
    )
}