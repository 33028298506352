import { URL_API } from "..";
import { getToken } from '../../auth';

export const updateStore = async (_store) =>{

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);
    

    const params = {
        method: 'PUT',
        headers: header,
        body: JSON.stringify(_store)
    };

    const request = await fetch(`${URL_API}companys/update`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const updateStorePayment = async (_store) =>{

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);
    

    const params = {
        method: 'PUT',
        headers: header,
        body: JSON.stringify(_store)
    };

    const request = await fetch(`${URL_API}companys/update/payment`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const updateStoreOpen = async (_store) =>{

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);
    

    const params = {
        method: 'PUT',
        headers: header,
        body: JSON.stringify(_store)
    };

    const request = await fetch(`${URL_API}companys/update/open`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const getStore = async (_store) => {

    if(!_store)
        return null;

    const header = new Headers();

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_API}companys/${_store}`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const getStoreByData = async (_store) => {

    if(!_store)
        return null;

    const header = new Headers();

    const params = {
        method: 'GET',
        headers: header
    };

    const paramsURL = [];
    Object.keys(_store).map(item => {
        paramsURL.push(`${item}=${_store[item]}`);
        return item;
    })

    const request = await fetch(`${URL_API}companys?${paramsURL.join('&')}`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const getStoreCategories = async (_store, _all = false) => {


    const header = new Headers();
    

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_API}companys/${_store}/categories${_all ? '?all=true' : ''}`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const getStoreProducts = async (_store, _all = false) => {

    const header = new Headers();

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_API}companys/${_store}/products${_all ? '?all=true' : ''}`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const getStoreProductById = async (_store, id = null) => {

    const header = new Headers();

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_API}companys/${_store}/products${id ? `?id=${id}` : ''}`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const getPaymentSystemList = async () => {

    const header = new Headers();

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_API}payment/list`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}


export const storeCategoryProductCreate = async (_data) => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_API}companys/create/category`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const storeCategoryProductUpdate = async (_data) => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'PUT',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_API}companys/update/category`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const storeCategoryProductDelete = async (_data) => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'DELETE',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_API}companys/delete/category`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}



export const storeProductCreate = async (_data) => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_API}companys/create/product`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const storeProductUpdate = async (_data) => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'PUT',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_API}companys/update/product`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const storeProductDelete = async (_data) => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'DELETE',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_API}companys/delete/product`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const storeComboCreate = async (_data) => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_API}companys/create/combo`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}


export const storeComboDelete = async (_data) => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'DELETE',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_API}companys/delete/combo`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const storeComboItemCreate = async (_data) => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_API}companys/create/comboitem`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const paymentList = async(_data) => {
    const header = new Headers();

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_API}companys/list/payment/${_data}`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;
}
