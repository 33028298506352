import React, { useState, useEffect, useCallback } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SelectButton } from 'primereact/selectbutton';

import { getStoreCategories, getStoreProducts } from '../../../services/api/store';
import { userAvailableItem } from '../../../services/api/user';



import '../../../assets/style/pages/user/availableitem/index.scss';

export const AvailableItem = (props) => {


    //console.log(props);

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getData = useCallback(()=>{

        setIsLoading(true);

        const getDataBase = async () => {
           
            const categories = await getStoreCategories(props.match.params.client);

            let products = await getStoreProducts(props.match.params.client);

            products = products.data.map(item => {
                const category = categories.data.filter(c => ~~item.categoria === ~~c.id )[0];
                if(category){
                    const d = {...item};
                    d.categoria_nome = category.categoria;
                    return d;
                }

                return null;
            });
            products = products.filter(item=> item);
            products = products.sort((a, b) => {
                return ~~a.categoria > ~~b.categoria ? 1 : (~~a.categoria < ~~b.categoria ? -1 : 0);
            })

            //console.log(products);

            setData(products);

            setIsLoading(false);
        }

        getDataBase();

    },[props]);

    useEffect(()=>{
        getData();

    },[getData])

    const updateRequest = async (_data) => {
        setIsLoading(true);
        await userAvailableItem(_data);
        getData();
    }

    return(
        <div className="page-user-availableitem">
            <div className="main-frame">
                <h3>
                    Disponibilidade dos itens
                </h3>
                {
                    data ?
                        <DataTable loading={isLoading} value={data} rowGroupMode="subheader"
                        rowGroupHeaderTemplate={(_data)=>{
                            return <strong>{_data.categoria_nome}</strong>
                        }}
                        rowGroupFooterTemplate={()=><></>}
                        groupField="categoria_nome" >
                            <Column field="produto" header="Produto"></Column>
                            <Column body={(_data)=>{
                                return (<>{_data.estoque} {_data.unidade}</>);
                            }} header="Estoque"></Column>
                            <Column body={(_data)=> {
                                return <SelectButton value={_data.terminal} options={[
                                    {label:'Ativar', value:'S'},
                                    {label:'Desativar', value:'N'}
                                ]} onChange={(e) => updateRequest({id:_data.id, terminal: e.value})}></SelectButton>
                            }} />

                        </DataTable>
                    :
                    <div style={{textAlign: 'center', height:'200px'}}> <ProgressSpinner style={{width: '36px', height: '36px', margin: '10px auto'}} /> </div>
                }
            </div>
        </div>
    )
}