import React,{useState, useEffect, createElement} from 'react';

import { ProgressSpinner } from 'primereact/progressspinner';

import { Icon } from 'react-icons-kit';
import { URL_API } from '../../services/api';


export const ImageLoader = (props) => {

    const [image, setImage] = useState(null);

    useEffect(()=>{
        
       const getData = async () => {

                const filename = props.image?.length > 0 ? props.image : 'noimage';
                //console.log('Filename', props?.store?.pasta, filename);
        
                const data = btoa(JSON.stringify({path:props?.store?.pasta, filename: filename }));

                const header = new Headers();

                const params = {
                    method: 'GET',
                    header: header
                }

                const request = await fetch(`${URL_API}/Files/GetFile?data=${JSON.stringify({data: data})}`, params);
                const result =  await request.blob();

                let blobImage = null;
                try{
                    blobImage = URL.createObjectURL(result);
                }catch(e){}

                const imageElement = createElement('img', {src:blobImage, style:{maxWidth:'100%'}});
                
                const imageTest = new Image();
                imageTest.src = blobImage;
                
                setImage(imageElement);

                imageTest.addEventListener('error', ()=> {
                    setImage(<Icon style={{color:'#aaa'}} size="5rem" icon={props.default} />);
                });

                
       }

       getData();


    },[props]);

    return (
        <div style={{display:'flex', alignItems:'center', justifyContent:'center', textAlign:'center', ...props.style}} className="image-loader">
            {
                image ? image : <ProgressSpinner/>
            }
        </div>
    )
}