import React, { useEffect } from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import { HeaderBar } from '../../components/header';

import { PageStore } from '../store';
import { PageLogin } from '../user/login';
import { PageSigup } from '../user/sigup';
import { PageUserProfile } from '../user/profile';
import { PageUserAddress } from '../user/address';
import { PageUserAddressCreate } from '../user/address/create';
import { PageUserPayment } from '../user/payment';
import { PageUserAddressUpdate } from '../user/address/update';
import { PageUserPaymentCreate } from '../user/payment/create';
import { PageUserPaymentUpdate } from '../user/payment/update';
import { PageUserRequests } from '../user/request';
import { PageUserRequestView } from '../user/request/view';
import { PageCartList } from '../cartlist';


import { ManagerRequest } from '../user/managerrequest';
import { AvailableItem } from '../user/availableitem';


import { PageAdmLogin } from '../admin/login';
import { PageAdmStore } from '../admin/store';
import { PageAdmProductCategory } from '../admin/category';
import { PageAdmProductList } from '../admin/product';
import { PageAdmProductCreate } from '../admin/product/create';
import { PageAdmProductUpdate } from '../admin/product/update';
import { PageAdmRequest } from '../admin/request';
import { PageAdmAvailableItem } from '../admin/availableitem';
import { PageAdmEmployee } from '../admin/employee';
import { PageAdmEmployeeCreate } from '../admin/employee/create';
import { PageAdmEmployeeUpdate } from '../admin/employee/update';
import { PageAdmHome } from '../admin/home';

import { PageDeliveryRequests } from '../delivery/requests';
import { PageDeliveryInRoute } from '../delivery/inroute';


import RoutePritvate from '../../components/navigation/private';

import '../../assets/style/pages/base/index.scss';

export const PageBase = (props) => {

    useEffect(()=>{

        //console.log('BASE');

    }, []);

    return (
        <div className="page-base">
            <header className="area-header">
                <HeaderBar {...props} />
            </header>
            <section>
                <BrowserRouter key={`${props.location.pathname.replace(/\//gi,'_')}`} basename="/">
                    <Switch>
                        <Route exact path="/adm" component={(p)=><PageAdmLogin {...p} {...props} />} />

                        <Route exact path="/:client/user/login" component={(p)=><PageLogin {...p} {...props} />} />
                        <Route exact path="/:client/user/sigup" component={(p)=><PageSigup {...p} {...props} />} />
                        <Route exact path="/:client" component={(p)=> <PageStore {...p} {...props} />} />
                        <Route exact path="/:client/user/cart" component={(p)=><PageCartList {...p} {...props} />} />
                        
                        <RoutePritvate exact path="/:client/user/address" component={(p)=> <PageUserAddress {...p} {...props} /> } />
                        <RoutePritvate exact path="/:client/user/address/create" component={(p)=> <PageUserAddressCreate {...p} {...props} /> } />
                        <RoutePritvate exact path="/:client/user/address/update/:addressid" component={(p)=> <PageUserAddressUpdate {...p} {...props} /> } />
                        <RoutePritvate exact path="/:client/user/payment" component={(p)=> <PageUserPayment {...p} {...props} /> } />
                        <RoutePritvate exact path="/:client/user/payment/create" component={(p)=> <PageUserPaymentCreate {...p} {...props} /> } />
                        <RoutePritvate exact path="/:client/user/payment/update/:paymentid" component={(p)=> <PageUserPaymentUpdate {...p} {...props} /> } />
                        <RoutePritvate exact path="/:client/user/requests" component={(p)=> <PageUserRequests {...p} {...props} /> } />
                        <RoutePritvate exact path="/:client/user/request/view/:requestid" component={(p)=> <PageUserRequestView {...p} {...props} /> } />
                        <RoutePritvate exact path="/:client/user" component={(p)=> <PageUserProfile {...p} {...props} /> } />
                        <RoutePritvate exact path="/:client/user/managerrequest" component={(p)=> <ManagerRequest {...p} {...props} /> } />
                        <RoutePritvate exact path="/:client/user/availableitem" component={(p)=> <AvailableItem {...p} {...props} /> } />

                        <Route exact path="/:client/adm/login" component={(p)=><PageAdmLogin {...p} {...props} />} />
                        <Route exact path="/:client/adm" component={(p)=><PageAdmLogin {...p} {...props} />} />
                        <RoutePritvate exact path="/adm/home" component={(p)=> <PageAdmHome {...p} {...props} /> } />
                        <RoutePritvate exact path="/adm/store" component={(p)=> <PageAdmStore {...p} {...props} /> } />
                        <RoutePritvate exact path="/adm/productcategory" component={(p)=> <PageAdmProductCategory {...p} {...props} /> } />
                        <RoutePritvate exact path="/adm/product" component={(p)=> <PageAdmProductList {...p} {...props} /> } />
                        <RoutePritvate exact path="/adm/product/create" component={(p)=> <PageAdmProductCreate {...p} {...props} /> } />
                        <RoutePritvate exact path="/adm/product/update/:product" component={(p)=> <PageAdmProductUpdate {...p} {...props} /> } />
                        <RoutePritvate exact path="/adm/managerrequest" component={(p)=> <PageAdmRequest {...p} {...props} /> } />
                        <RoutePritvate exact path="/adm/availableitem" component={(p)=> <PageAdmAvailableItem {...p} {...props} /> } />
                        <RoutePritvate exact path="/adm/employee" component={(p)=> <PageAdmEmployee {...p} {...props} /> } />
                        <RoutePritvate exact path="/adm/employee/create" component={(p)=> <PageAdmEmployeeCreate {...p} {...props} /> } />
                        <RoutePritvate exact path="/adm/employee/update/:employeedid" component={(p)=> <PageAdmEmployeeUpdate {...p} {...props} /> } />

                        <Route exact path="/adm/login" component={(p)=><PageAdmLogin {...p} {...props} />} />
                        <RoutePritvate exact path="/adm/delivery" component={(p)=><PageDeliveryRequests {...p} {...props} />} />
                        <RoutePritvate exact path="/adm/inroute/:id" component={(p)=><PageDeliveryInRoute {...p} {...props} />} />

                        <Route path="*" component={()=><>404 - {props.match.params.client} - {props.location.pathname}</>} />
                    </Switch>
                </BrowserRouter>
            </section>
        </div>
    )
}