export const URL_API = (/development/gi).test(process.env.NODE_ENV) ?  'http://localhost/pedidosweb/api/' : '/api/';
//export const URL_API = (/development/gi).test(process.env.NODE_ENV) ?  'http://192.168.1.241/pedidosweb/api/' : '/api/';
// export const URL_APLICATION = 'https://www.lanchar.com.br/';
// export const URL_ROOT =  (/development/gi).test(process.env.NODE_ENV) ? 'http://localhost:3000' : 'https://www.lanchar.com.br/';

//export const URL_API = (/development/gi).test(process.env.NODE_ENV) ?  'http://pedidosweb.tokin.com.br/api/' : '/api/';
export const URL_APLICATION = 'https://www.lanchar.com.br/';
export const URL_ROOT =  (/development/gi).test(process.env.NODE_ENV) ? 'http://localhost:3000' : 'https://www.lanchar.com.br/';

export const TOKEN_GOOGLE_MAPS = 'AIzaSyCkvgEJgpHgghtycz4tpbV1KO637CUO-ko';
export const URL_GOOGLE_MAPS_GEOLOCATION = `https://www.googleapis.com/geolocation/v1/geolocate?key=${TOKEN_GOOGLE_MAPS}`;
export const URL_GOOGLE_MAPS_GETROUTER = `https://maps.googleapis.com/maps/api/directions/json?key=${TOKEN_GOOGLE_MAPS}&mode=metric`;
export const URL_GOOGLE_MAPS_GETDISTANCE = `https://maps.googleapis.com/maps/api/distancematrix/json?units=METRIC&key=${TOKEN_GOOGLE_MAPS}`;