import { URL_APLICATION } from "../api";

export const pwaManifest = (e) => {
    //console.log('CRIA MANIFEST');
    
    var t={
        short_name: e.nome,
        name: e.nome,
        description: e?.detalhe?.slogan ? e?.detalhe?.slogan : '',
        start_url: `${window.location.origin}/${e.id}`,
        display:"standalone",
        theme_color:"#ffffff",
        background_color:"#ffffff",
        icons:[
            {
                src:  `${ e?.detalhe?.icone ? `${URL_APLICATION}${e?.detalhe?.icone}` : `${window.location.origin}/favicon.ico`}`,
                "type": "any"
            },
            {
                src: `${ e?.detalhe?.icone ? `${URL_APLICATION}${e?.detalhe?.icone}` : `${window.location.origin}/logo192.png`}`,
                "type": `any`
            }
        ]
        }

        const a = new Blob([JSON.stringify(t)],{type:"application/json"});
        const n = document.querySelector('link[rel*="manifest"]');
        n.setAttribute("href",window.URL.createObjectURL(a));
}

export const pwaServices = (_id) => {
    //console.log('CRIA Service');
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register(`${window.location.origin}/service-worker.js?id=${_id}`)
        .then(function () {
            //console.log('service worker registered');
        })
        .catch(function () {
            console.warn('service worker failed');
        });
      }
}