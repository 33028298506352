import React, {useState, useRef, useEffect, useCallback, useMemo} from 'react';

import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';

import { addLocale } from 'primereact/api';

import { useAPP } from '../../../services/provider/app';

import { useForm, Controller } from 'react-hook-form';

import { userProfile, userUpdate } from '../../../services/api/user';

import { localeData } from '../../../services/utils';

import '../../../assets/style/pages/user/profile/index.scss';

export const PageUserProfile = (props) => {

    addLocale('br', localeData.br);

    const defaultValues = useMemo(()=>({
        id:'',
        nome:'',
        email:'',
        rg:'',
        sexo:'',
        nascimento:'',
        telefone:'',
        celular:'',
        cpf: '',
        senha: ''
    }),[])

    const [isLoad, setIsLoad] = useState(false);

    const form = useForm({ defaultValues });
    const control =  form.control;
    const errors  = form.formState.errors ;
    const handleSubmit = form.handleSubmit;

    const {user, setUser} = useAPP();

    const toast = useRef(null);

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const onSubmit = async (_data) => {
        setIsLoad(true);

        _data.id = user.id;

        //console.log(_data);

        const data = await userUpdate(_data);

        setIsLoad(false);
        if(data.data){
            setUser(_data);
            getData();
            toast.current.show({severity: 'success', summary: 'Sucesso', detail: 'Usuário cadastrado.'});
        }else{
            toast.current.show({severity: 'error', summary: 'Erro', detail: 'Usuário não cadastrado. Tente novamente.'});
        }
    }

    const populateData = useCallback(_data => {
        const data = {..._data};

        const updatedate = new Date(data?.nascimento);
        data.nascimento = updatedate;

        //console.log(data);

        let keys = Object.keys(defaultValues);
        keys = keys.filter(item => !(/senha/gi).test(item));

        keys.map(item => {
            form.setValue(item, data[item]);
            return item;
        })

    }, [defaultValues, form])

    const getData = useCallback(() => {
        const getDataUser = async () => {
            const data = await userProfile();
             const updatedate = new Date(data.data?.nascimento);
             data.data.nascimento = updatedate;
            //console.log(data.data);
            setUser(data.data);
            populateData(data.data);
        }

        getDataUser();
    },[populateData, setUser])

    useEffect(()=> {
        if(!user){
            getData();
        }else{
            populateData(user);
        }

    }, [user, populateData, getData]);


    return (
        <div className="page-user-profile">
            <div className="main-frame">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h3>
                        Dados do usuário
                    </h3>
                    
                    <Divider>
                        <i className="pi pi-user p-mr-2" />
                        <strong style={{fontSize:'1em'}}>
                            Dados
                        </strong>
                    </Divider>

                    <div className="p-grid p-mt-4">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="nome" control={control} rules={{ required: 'Campo obrigatório' }} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} autoFocus />
                                    )} />
                                    <label htmlFor="nome">Nome *</label>
                                </span>
                                {getFormErrorMessage('nome')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="email" control={control} rules={{ required: 'Campo obrigatório' }} render={({ field, fieldState }) => (
                                        <InputText type="email" style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="email">E-mail *</label>
                                </span>
                                {getFormErrorMessage('email')}
                            </div>
                        </div>
                    </div>

                    <div className="p-grid p-mt-2">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="rg" control={control} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="rg">RG</label>
                                </span>
                                {getFormErrorMessage('rg')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="sexo" control={control}  render={({ field, fieldState }) => (
                                        <Dropdown id={field.name} value={field.value} options={
                                            [{label:'Masculino', value:'M'}, {label:'Feminino', value:'F'}, {label:'Indefinido', value:'U'}]
                                        } onChange={(e) => field.onChange(e.value)} style={{width:'100%'}} {...field}/>
                                    )} />
                                    <label htmlFor="sexo">Sexo</label>
                                </span>
                                {getFormErrorMessage('sexo')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="nascimento" control={control} render={({ field, fieldState }) => (
                                        <Calendar showIcon monthNavigator yearNavigator yearRange={`1900:${new Date().getFullYear()}`}  locale="br" dateFormat="dd/mm/yy" value={field.value} onChange={(e) => field.onChange(e.value)} style={{width:'100%'}} id={field.name} {...field}></Calendar>
                                    )} />
                                    <label htmlFor="nascimento">Data nascimento</label>
                                </span>
                                {getFormErrorMessage('nascimento')}
                            </div>
                        </div>
                    </div>

                    <div className="p-grid p-mt-2">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="telefone" control={control} render={({ field, fieldState }) => (
                                        <InputText type="tel" style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="telefone">Telefone</label>
                                </span>
                                {getFormErrorMessage('telefone')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="celular" control={control} render={({ field, fieldState }) => (
                                        <InputText type="tel" style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="celular">Celular</label>
                                </span>
                                {getFormErrorMessage('celular')}
                            </div>
                        </div>
                    </div>

                    <Divider>
                        <i className="pi pi-shield p-mr-2" />
                        <strong style={{fontSize:'1em'}}>
                            Acesso
                        </strong>
                    </Divider>

                    <div className="p-grid p-mt-4">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="cpf" control={control} rules={{ required: 'Campo obrigatório' }} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="cpf">CPF *</label>
                                </span>
                                {getFormErrorMessage('cpf')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="senha" control={control} render={({ field, fieldState }) => (
                                         <>
                                            <Password  maxLength={6} inputStyle={{width: '100%', pointerEvents:'none'}} feedback={false} toggleMask style={{width:'100%'}}  {...field} id={field.name}  value={field.value} onChange={(e) => field.onChange(e.target.value.replace(/\D/gi,''))} pattern="[0-9]{6}" />
                                            <input style={{opacity:1, border:0, color:'transparent', background:'transparent', outline:'0', position:'absolute', bottom:'2px', left:'1px', height:'30px', width:'calc(100% - 36px)'}} type="number" maxLength={6}  onChange={(e) => field.onChange(e.target.value.replace(/\D/gi,''))} />
                                        </>
                                    )} />
                                    <label htmlFor="senha">Senha (Somente números e 6 dígitos) *</label>
                                </span>
                                {getFormErrorMessage('senha')}
                            </div>
                        </div>
                    </div>

                    <div className="p-grid p-mt-2 p-mb-2">
                        <div className="p-col-12 p-md-6 p-md-offset-6 area-button">
                            <Button loading={isLoad} type="submit" label="Atualizar" />
                        </div>
                    </div>

                </form>
            </div>
            <Toast ref={toast} position="bottom-center" />
        </div>
    )
}