import React, {useState, useRef, useEffect, useCallback} from 'react';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';

import { useForm, Controller } from 'react-hook-form';

import { ImageLoader } from '../../imageloader';

import { addCartItem, getCartList } from '../../../services/shoppingcart';

import { useAPP } from '../../../services/provider/app';

import { formatToMoney } from '../../../services/utils';



import {ic_local_dining} from 'react-icons-kit/md/ic_local_dining';

import '../../../assets/style/components/cards/product/index.scss';
import { getToken } from '../../../services/auth';

export const CardProduct = (props) => {


    let token = getToken();
    if(token){
        token = atob(token);
        token = JSON.parse(token);
    }


    let defaultValues = {
        description:''
    };

    (props.combo || []).map(item => {

        defaultValues[`combo_${item.id}`] = 0;

        return item;
    })

    const form = useForm({ defaultValues });
    const control =  form.control;
    const reset = form.reset;
    const handleSubmit = form.handleSubmit;

    const [showDialog, setShowDialog] = useState(false);
    const [total, setTotal] = useState(props.preco);
    const [quantity, setQuantity] = useState(1);

    const image = useRef(<ImageLoader store={props?.store} image={props?.image} default={ic_local_dining} />);
    const formData = useRef();

    const toast = useRef(null);

    const { setVersion } = useAPP();

    const getComboName = () => {
        return [...new Set(props.combo.map(item => item.combo_nome))]
    }

    const getItemsMyComboName = (_combo) => {
        return props.combo.filter(item => (new RegExp(item.combo_nome, 'gi').test(_combo)));
    }

    const updatePrice = useCallback(() => {

        const listItensData = form.getValues()

        const listItens =  Object.keys(listItensData).filter(item => (/combo/gi).test(item));
        
        const aditionalItems = listItens.reduce((_c, _n)=>{
            const _total = ~~listItensData[_n];
            const _item = (props.combo || []).filter(item => ~~item.id === ~~(_n.toString().replace(/\D/gi,'')))[0];
            const _price = _item ? parseFloat(_item.preco) : 0;
            return _c + (_total * _price);

        }, 0);

        setTotal((quantity * props.preco) + (quantity * aditionalItems) - (quantity * parseFloat(props.desconto)));
    }, [quantity, props, form])

    const setStarted = () => {
        reset();
        setQuantity(1);
    }

    const onSubmit = (_data) => {
        //console.log(_data);

        const listItens =  Object.keys(_data).filter(item => (/combo/gi).test(item));

        let database = {
            id: props.id,
            name: props.nome,
            price: props.preco,
            descont: props.desconto,
            quantity: quantity,
            description: _data.description,
            combo: null
        }

        if(props.combo){
            database.combo = listItens.map(el => {                
                const _total = ~~_data[el];
                const _item = props.combo.filter(item => ~~item.id === ~~(el.toString().replace(/\D/gi,'')))[0];
                if(_total > 0){
                    return {
                        id: _item.id,
                        name: _item.nome,
                        price: _item.preco,
                        quantity: _total

                    };
                }
                return el;
            })

            database.combo = database.combo.filter(item => item !== undefined);
        }

        ////console.log('Add');
        //console.dir(database);
        addCartItem(database);
        setVersion(getCartList().length);

        setShowDialog(false);

        toast.current.show({severity: 'success', summary: 'Sucesso', detail: <>Item adicionado com sucesso. <Button onClick={()=> props.history.push(`/${props.match.params.client}/user/cart`)} label="Ir para carrinho" className="p-button-text p-button-sm" /></>});

    }

    const getCardType = () => {
        const cartTypes = {
            product: 'card-product',
            promotional: 'card-promotional'
        }

        let type = cartTypes.product;


        if(cartTypes[props.type])
            type = cartTypes[props.type];

        return type;
    }

    useEffect(()=>{

        updatePrice();

    }, [quantity, updatePrice, showDialog]);


    return (
        <div className={`${getCardType()} p-shadow-2`}>
            <div className="area-image">
                {image.current}
            </div>
            <div className="product-name">
                {props.nome}
            </div>
            <div className="product-description">
                {props.detalhes}
            </div>
            <div className="product-price">
                {
                    parseFloat(props.desconto) > 0 ?
                    <><del style={{fontSize:'0.8em'}}>{formatToMoney(props.preco)}</del> <strong> { formatToMoney(parseFloat(props.preco) -  parseFloat(props.desconto))}</strong> </>
                    :
                        formatToMoney(props.preco)
                }
            </div>
            <div className="product-action">
                {
                    !token?.empresa_id ?
                        <>
                            {
                                (/product/gi).test(getCardType()) ?
                                    <Button onClick={()=>{ setStarted(); setShowDialog(true);}} className="" icon="pi pi-shopping-cart" />
                                :
                                    <Button onClick={()=>{ setStarted(); setShowDialog(true);}} style={{width:'100%'}} className="p-button-warning" icon="pi pi-shopping-cart" />
                            }
                        </>
                    :
                    <>
                        {
                            (/product/gi).test(getCardType()) ?
                                <Button className="p-button-secondary"icon="pi pi-lock" style={{pointerEvents:'none'}} ></Button>
                            :
                                <Button className="p-button-secondary " style={{width:'100%', textAlign:'center', justifyContent:'center', pointerEvents:'none'}} >Modo Administrativo</Button>
                        }
                    </>
                }
            </div>
            <Dialog header="Adicionar ao carrinho" visible={showDialog} style={{ width: '950vw', maxWidth: '600px' }} footer={()=><>
                    
                    <hr />
                    <div className="p-grid p-mt-4 p-ai-center">
                        <div className="p-col-7 p-md-8">
                            <InputNumber inputStyle={{textAlign:'center', pointerEvents:'none'}} style={{fontSize:'0.8em'}} buttonLayout="horizontal" decrementButtonClassName="p-button-sm p-m-0" incrementButtonClassName="p-button-sm p-m-0" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" min="1" value={quantity} onChange={(e)=> setQuantity(e.value)} size="3" showButtons  />
                        </div>
                        <div className="p-col-5 p-md-4">
                            <strong>Total</strong>: {formatToMoney(total)}    
                        </div>
                    </div>
                    <div className="p-d-flex p-jc-end">
                        <div className="p-col-fixed" style={{width:'250px'}}>
                            <Button onClick={()=>{setShowDialog(false)}} style={{width:'100px'}} label="Cancelar" className="p-button-danger p-mr-2 p-ml-3" />
                            <Button onClick={handleSubmit(onSubmit)} style={{width:'100px'}} label="Adicionar" className="p-button" />
                        </div>
                    </div>
                </>
            } onHide={() => setShowDialog(false)}>
                <form ref={formData} onSubmit={handleSubmit(onSubmit)}>
                    <div className="p-grid">
                        <div className="p-col-3">
                                {image.current}
                        </div>
                        <div className="p-col-9">
                            <h2>{props.nome}</h2>
                            <div>
                                {props.detalhes}
                            </div>
                        </div>
                    </div>
                    {
                        props.combo ?
                            <>
                                <Divider>
                                    <strong>Itens adicionais</strong>
                                </Divider>
                                {
                                    getComboName().map((item, id) => {
                                    return <div key={`item-add-${id}`} style={{boxSizing:'border-box', padding:'0 10px', width:'98%'}}>
                                            <h3>{item}</h3>
                                            {getItemsMyComboName(item).map(product => {
                                                return <div key={`item-add-product-${product.id}`} style={{width:'98%'}} className="p-grid">
                                                    <div className="p-col-9">
                                                        <div><strong style={{fontSize:'0.8em'}}>{product.nome}</strong></div>
                                                        <div style={{fontSize:'0.8em', fontWeight: 200}}>{product.detalhes}</div>
                                                        <div style={{fontSize:'0.8em'}}>
                                                            {formatToMoney(product.preco)}
                                                        </div>
                                                    </div>
                                                    <div className="p-col-3">
                                                        <Controller name={`combo_${product.id}`} control={control} defaultValue={0} render={({ field, fieldState }) => (
                                                            <InputNumber inputStyle={{textAlign:'center', pointerEvents:'none'}} style={{fontSize:'0.8em'}} id={field.name} value={field.value} onChange={(e)=>{field.onChange(e.value); updatePrice(); }}  buttonLayout="horizontal" decrementButtonClassName="p-button-sm p-m-0" incrementButtonClassName="p-button-sm p-m-0" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" min="0" max={~~product.maximo} size="2" showButtons />
                                                        )} />
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    })
                                }
                            </>
                        :
                        ''
                    }
                    <Divider>
                        <strong>Informações adicionais</strong>
                    </Divider>
                    <Controller name={`description`} control={control}  render={({ field, fieldState }) => (
                        <InputTextarea style={{width: '100%'}} id={field.name} value={field.value} onChange={(e)=> field.onChange(e.target.value)} autoResize={true} rows={2} cols={30} placeholder="Exemplo: Retirar item, trocar item" />
                    )} />
               </form>
            </Dialog>
            <Toast ref={toast} position="bottom-center" />
        </div>
    )
}