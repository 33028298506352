export const formatToMoney = (_val) => {

    if(_val)
        return `R$ ${parseFloat(_val).toFixed(2)}`.replace('.',',');
    else
        return `R$ ${parseFloat(~~_val).toFixed(2)}`.replace('.',',');

}

export const formatToCredidCard = (_val) => {
    const numTem = [];

    _val.split('').map((item, id) =>{
        if((id + 1) % 4 === 0){
            numTem.push(item);
            numTem.push(' ');
        }else{
            numTem.push(item);
        }
        return item;
    } )

    return numTem.join('');
}

export const localeData = {
    br:{
        firstDayOfWeek: 1,
        dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        dayNamesShort: ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['JAN', 'FEB', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
        today: 'Hoje',
        clear: 'Limpar'
    }
}


export const cards = {
    visa      : /^4[0-9]{12}(?:[0-9]{3})/,
    mastercard : /^5[1-5][0-9]{14}/,
    diners    : /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    amex      : /^3[47][0-9]{13}/,
    discover  : /^6(?:011|5[0-9]{2})[0-9]{12}/,
    hipercard  : /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
    elo        : /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
    jcb        : /^(?:2131|1800|35\d{3})\d{11}/,       
    aura      : /^(5078\d{2})(\d{2})(\d{11})$/     
};

export const getCreditCardFlag = (_data) => {
    const cardNumber = _data.replace(/\D/gi, '');

    const flags = Object.keys(cards);
    
    const current =  flags.filter(item => (cards[item].test(cardNumber)))[0];

    return current;

}