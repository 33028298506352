import React, {useState, useEffect, useRef, useMemo} from 'react';

import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FileUpload } from 'primereact/fileupload';

import { useForm, Controller } from 'react-hook-form';
import { getStoreCategories, storeComboCreate, storeComboItemCreate, storeProductCreate, storeProductUpdate, getStore } from '../../../../services/api/store';

import { getToken } from '../../../../services/auth';

import { URL_API } from '../../../../services/api';

import '../../../../assets/style/pages/adm/product/index.scss';
import { formatToMoney } from '../../../../services/utils';

export const PageAdmProductCreate = (props) => {


    const [categories, setCategories] = useState();
    const [combo, setCombo] = useState([]);

    const [categoryCreate, setCategoryCreate] = useState(null);
    const [isLoad] = useState(false);

    const [expandedRows, setExpandedRows] = useState(null);

    const refAvatar = useRef();

    const defaultForm = useMemo(() => ({
        desconto: 0,
        detalhes: '',
        nome: '',
        preco: 0,
        produto_categoria_id: 0,
        promocional: false
    }),[]);

    const form = useForm({defaultForm});

    const control = form.control;
    const errors = form.formState.errors;
    const reset  = form.reset;
    const handleSubmit =  form.handleSubmit;

    const formProduct = useForm({
        nome:'',
        preco: 0,
        desconto: 0
    });
    
    const controlProduct = formProduct.control;
    //const errorsProduct = formProduct.formState.errors;
    const resetProduct = formProduct.reset;
   // const handleSubmitProduct =  formProduct.handleSubmit;


    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const createCategory = () => {
        const temp = [...combo];
        temp.push({nome: categoryCreate, combo: []});

        setCombo(temp);
    }

    const removeCategory = (_data) => {
        const temp = combo.filter(item => item !== _data);

        setCombo(temp);
    }

    const createProduct = (_data) => {
        const temp = [...combo];
        let id = null;

        temp.map((item, _id) => {
            if(item === _data)
                id = _id

            return item;
        });

        //console.log('#', id);

        const values = formProduct.getValues();

        const productData = {
            combo_produto_nome:'',
            combo_produto_preco:'',
            combo_produto_desconto:'',
            combo_produto_descricao:''
        }

        Object.keys(productData).map(item => {
            productData[item] = values[`${item}__${_data.nome}`];
            return item;
        })

        if(!isNaN(id))
            temp[id].combo.push({...productData});

        const tempReset  = {...formProduct.getValues()};

        //console.log('#',tempReset);

        Object.keys(tempReset).map(item => {
            tempReset[item] = '';
            return item;
        })

        //console.log('@', tempReset);


        resetProduct(tempReset);

        setCombo(temp);
    }

    const removeProduct = (_data, _product) => {
        const temp = [...combo];
        let id = null;

        temp.map((item, _id) => {
            if(item === _data)
                id = _id

            return item;
        });

        if(!isNaN(id))
            temp[id].combo = temp[id].combo.filter(item => item !== _product);

        setCombo(temp);
    }

    const uploadImage = async (_data ) => {

        const header = new Headers();

        const params = {
            method: 'POST',
            header: header,
            body: _data
        }

        const result = await fetch(`${URL_API}/Files/Upload`, params);

        return result;
    }

    const onSubmit = async (data) => {
        //console.log(data);

        const token = getToken();
        const store =  JSON.parse(atob(token));

        const _data = {...data};
        _data.empresa_id = store.empresa_id;
        _data.avatar = '';
        

        // Product
        const productId = await storeProductCreate(_data);
        //console.log('Produto novo', productId);

        const storeTemp = await getStore(store.empresa_id);        

        if(data.avatar?.files){
            const fileAvatar = refAvatar.current.files[0]?.name?.split('.');
            const avatar = `${productId.data}.${fileAvatar[fileAvatar.length - 1]}`;

            const formImageAvatar = new FormData();
            formImageAvatar.append('filename', productId.data);
            formImageAvatar.append('path', storeTemp.data?.pasta);
            formImageAvatar.append('file', refAvatar.current.files[0]);

            await uploadImage(formImageAvatar);        

            await storeProductUpdate({id: productId.data, avatar: avatar});

        }else{
            _data.avatar = data.avatar;
        }

        // Avatar

        //console.log(combo);

        // Combo

        let comboIndex = 0;

        const comboCreate = async () => {

            if(comboIndex < combo.length){

                const product_combo = {
                    produto_id: productId.data,
                    nome: combo[comboIndex].nome,
                    minimo: 0,
                    maximo: 10,
                    obrigatorio: 0
                }

                const comboID = await storeComboCreate(product_combo);

                const productsCombo = combo[comboIndex].combo.map(item => {
                    const productComboItem = {
                        empresa_id:  store.empresa_id,
                        produto_categoria_id: null,
                        nome: item.combo_produto_nome,
                        detalhes: item.combo_produto_descricao,
                        preco: item.combo_produto_preco,
                        desconto: item.combo_produto_desconto
                    }

                    return storeProductCreate(productComboItem);
                });

                const productslistids = await Promise.all(productsCombo);

                const comboitem = productslistids.map(prod => {
                    const ci = {
                        produto_combo_id: comboID.data,
                        produto_id: prod.data
                    }
                    return storeComboItemCreate(ci);
                })

                
                await Promise.all(comboitem);

                comboIndex++;

                return await comboCreate();

            }else{
                return true;
            }

        }


        await comboCreate();


    }



    const rowExpansionTemplate = (_data) => {
        //console.log(_data);
        return (<>
                    <h3>Criar produto combo - ({_data.nome}) </h3>
                    <div className="p-grid p-mt-4">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name={`combo_produto_nome__${_data.nome}`}  control={controlProduct}  render={({ field, fieldState }) => (
                                        <InputText type="text" style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor={`combo_produto_nome__${_data.nome}`}>Nome</label>
                                </span>
                                {getFormErrorMessage(`combo_produto_nome__${_data.nome}`)}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name={`combo_produto_preco__${_data.nome}`} control={controlProduct}  render={({ field, fieldState }) => (
                                        <InputText  value={field.value} style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor={`combo_produto_preco__${_data.nome}`}>Preço</label>
                                </span>
                                {getFormErrorMessage(`combo_produto_preco__${_data.nome}`)}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name={`combo_produto_desconto__${_data.nome}`} defaultValue="0" control={controlProduct}  render={({ field, fieldState }) => (
                                        <InputText type="text" style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor={`combo_produto_desconto__${_data.nome}`}>Desconto</label>
                                </span>
                                {getFormErrorMessage(`combo_produto_desconto__${_data.nome}`)}
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name={`combo_produto_detalhes__${_data.nome}`} control={controlProduct}  render={({ field, fieldState }) => (
                                        <InputTextarea autoResize rows={5} style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor={`combo_produto_detalhes__${_data.nome}`}>Detalhes</label>
                                </span>
                                {getFormErrorMessage(`combo_produto_detalhes__${_data.nome}`)}
                            </div>
                        </div>
                        <div className="p-col-12" style={{textAlign:'right'}} >
                            <Button onClick={(e) => { e.preventDefault(); createProduct(_data); }} label={`Salvar produto combo (${_data.nome})`} className="p-button p-mb-4" />
                        </div>
                    </div>
                <DataTable value={_data.combo} >
                    <Column field="combo_produto_nome" header="Produto"></Column>
                    <Column body={(row)=>{
                        return formatToMoney(row.combo_produto_preco);
                    }} field="combo_produto_preco" header="Preço"></Column>
                    <Column body={(row)=>{
                        return formatToMoney(row.combo_produto_desconto);
                    }} field="combo_produto_desconto" header="Desconto"></Column>
                    <Column style={{width:'80px'}} body={(row)=>{
                        return <>
                            <Button onClick={(e)=>{removeProduct(_data, row); }} icon="pi pi-times" className="p-button-danger" />
                        </>
                    }} header=""></Column>
                </DataTable>  
            </>
        )
    }

    useEffect(()=>{
        //console.log(combo);
        setCategoryCreate('');
    },[combo])

    useEffect(()=> {
        const getCategories = async () => {
            const token = getToken();
            const store =  JSON.parse(atob(token));
            const data = await getStoreCategories(store.empresa_id);
            const categories_data = data.data.map(item => ({label: item.nome, value: item.id}) );
            setCategories(categories_data);
        }

        getCategories();
        reset(defaultForm);
    }, [defaultForm, reset])

    return(
        <div className="page-adm-productcreate">
            <div className="main-frame">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h3>
                        Criar produto
                    </h3>
                    <Divider>
                        <strong style={{fontSize:'1em'}}>
                            Dados
                        </strong>
                    </Divider>
                    <div className="p-grid p-mt-4">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="nome" control={control}  render={({ field, fieldState }) => (
                                        <InputText type="text" value={field.value} style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="nome">Nome</label>
                                </span>
                                {getFormErrorMessage('nome')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="produto_categoria_id" control={control}  render={({ field, fieldState }) => (
                                        <Dropdown options={categories} value={field.value} onChange={(e)=> field.onChange(e.value) } style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="produto_categoria_id">Categoria</label>
                                </span>
                                {getFormErrorMessage('produto_categoria_id')}
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="detalhes" control={control}  render={({ field, fieldState }) => (
                                        <InputTextarea autoResize rows={5} value={field.value} style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="detalhes">Detalhes</label>
                                </span>
                                {getFormErrorMessage('detalhes')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="preco" control={control}   render={({ field, fieldState }) => (
                                        <InputText value={field.value}  onChange={(e)=> field.onChange(e.value) } style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="preco">Preço</label>
                                </span>
                                {getFormErrorMessage('preco')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="desconto" control={control}  render={({ field, fieldState }) => (
                                        <InputText  value={field.value} onChange={(e)=> field.onChange(e.value) } style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="desconto">Desconto</label>
                                </span>
                                {getFormErrorMessage('desconto')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Controller name="promocional" control={control}  render={({ field, fieldState }) => (
                                    <Checkbox checked={field.value} value={field.value} onChange={(e)=>{field.onChange(e.checked)}} id={field.name} {...field} />
                                )} />
                                <label className="p-checkbox-label p-ml-2" htmlFor="promocional">Este item é promocional?</label>
                        </div>
                        <div className="p-col-12 p-md-12">
                            <h3>
                                Imagem produto
                            </h3>
                            <Controller name="avatar" control={control} render={({ field, fieldState }) => (
                                <FileUpload ref={ref => { refAvatar.current = ref}}  customUpload onSelect={(e)=> { field.onChange(e); }} chooseLabel="Selecionar" cancelLabel="Cancelar" name="img" accept="image/*" emptyTemplate={<p className="p-m-0">Arraste e solte o arquivo aqui para upload.</p>} />
                            )} />
                        </div>
                    </div>
                    <Divider>
                        <strong style={{fontSize:'1em'}}>
                            Combos
                        </strong>
                    </Divider>
                    <div className="p-grid p-mt-4 p-mb-4">
                        <div className="p-col-12">
                            <div className="p-inputgroup">
                                <InputText value={categoryCreate} onChange={(e)=> setCategoryCreate(e.target.value)} placeholder="Nome categoria"/>
                                <Button onClick={(e)=>{ e.preventDefault(); createCategory(); } } loading={isLoad} label="Criar nova categoria"/>
                            </div>
                        </div>
                    </div>
                    <DataTable value={combo}  
                        rowExpansionTemplate={rowExpansionTemplate}
                        dataKey="id"
                        expandedRows={expandedRows} 
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        he
                    >
                        <Column expander style={{ width: '3em' }} />
                        <Column body={(row)=> <strong>{row.nome}</strong>} field="nome" header="Categoria"></Column>
                        <Column style={{width:'80px'}} body={(row)=>{
                            return <>
                                {/* <Button onClick={()=> createProduct(row)} icon="pi pi-plus" className="p-button-success p-mr-2" /> */}
                                <Button onClick={()=> removeCategory(row)} icon="pi pi-times" className="p-button-danger" />
                            </>
                        }} header=""></Column>
                    </DataTable>
                    <Divider className="p-mb-4 p-mt-4" />
                    <div className="p-d-flex p-mt-2 p-jc-end p-mt-7">
                        <Button onClick={()=> ( props?.location?.state?.callback ? props.history.push(props.location.state.callback) : props.history.push(`/adm/product`)) } label="Cancelar" className="p-button-danger p-mr-2" style={{width:'100px'}} />
                        <Button type="submit" loading={isLoad} label="Cadastrar" className="p-button" style={{width:'100px'}} />
                    </div>
                </form>
            </div>
        </div>
    )
}