import React, {useState, useEffect} from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import { getEmployeed, updateEmployeed, deleteEmployeed } from '../../../services/api/employee';

import '../../../assets/style/pages/adm/employee/index.scss';

export const PageAdmEmployee = (props) => {

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [globalFilter, setGlobalFilter] = useState();


    const getList = async () => {
        
        setIsLoading(true);

        const dataTemp = await getEmployeed();

        setIsLoading(false);

        setData(dataTemp.data);
    }

    const updateStatus = async (data) => {
        setIsLoading(true);
        await updateEmployeed({id:data.id, ativo: ~~data.ativo === 1 ? 0 : 1});
        await getList();
    }

    const deleteFunc = async (data) => {
        setIsLoading(true);
        await deleteEmployeed({id:data.id});
        await getList();
    }

    useEffect(()=>{

        const getData = async () => {
            await getList();
        }

        getData();
        
    }, []);

    return (
        <div className="pageadm-employee">
            <div className="main-frame">
                <h3>
                    Gerenciar funcionários
                </h3>
                <div className="p-grid p-mt-4 p-mb-4">
                    <div className="p-col-12" style={{textAlign:'right'}}>
                        <Button onClick={()=> props.history.push( `/adm/employee/create`) } loading={isLoading} label="Adicionar novo Funcionário"/>
                    </div>
                </div>
                <DataTable 
                header={<div style={{textAlign:'right'}}><span className="p-input-icon-left"><i className="pi pi-search" /><InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Busca funcionário..." /></span></div>} globalFilter={globalFilter} 
                loading={isLoading} value={data} >
                    <Column field="nome" header="Funcionário" sortable />
                    <Column style={{textTransform:'capitalize' }}  body={(row)=> <>
                        {row?.cargos?.map(item => <span style={{margin:'0 5px'}} >{item.nome}</span>)}
                    </>} header="Cargo"  />
                    <Column style={{width:'130px'}} field="ativo" header="Status" sortable body={(row)=><>
                        <div style={{textAlign:'center'}}>
                            {~~row.ativo === 1 ? <Tag value="Ativo" severity="success"></Tag> : <Tag value="Desativado" severity="danger"></Tag>}
                        </div>
                    </>} />
                    <Column style={{width:'180px', textAlign:'center'}} header="Ações" body={(row)=><>
                        <Button onClick={()=>{
                            props.history.push({pathname:`/adm/employee/update/${row.id}`, state:{data: row}});
                        }} icon="pi pi-pencil" className="p-button-text p-button" />
                        <Button onClick={()=> {updateStatus(row)}} icon={`pi ${~~row.ativo === 1 ? 'pi-eye' : 'pi-eye-slash' }`} className="p-button-text p-button-success" />
                        <Button onClick={()=> {deleteFunc(row)}} icon="pi pi-trash" className="p-button-text p-button-danger" />
                    </>} />
                </DataTable>
            </div>
        </div>
    )
}