import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';


import { addLocale } from 'primereact/api';

import { getCartList, removeCartItem, clearCart } from '../../services/shoppingcart';
import { formatToCredidCard, formatToMoney } from '../../services/utils';

import { userAddress, userPayment, userRequestCreate, userRequestCreateitem } from '../../services/api/user';

import { paymentList } from '../../services/api/store';


import { useAPP } from '../../services/provider/app';

import { isAuthenticated, getToken } from '../../services/auth';

import { localeData } from '../../services/utils';

import '../../assets/style/pages/cart/index.scss';
import { URL_GOOGLE_MAPS_GETDISTANCE } from '../../services/api';

export const PageCartList = (props) => {


    addLocale('br', localeData.br);

    const [isLoad, setIsLoad] = useState(false);
    const [deliverylist, setDeliverylist] = useState();
    const [delivery, setDelivery] = useState();
    const [deliveryPrice, setDeliveryPrice] = useState(0);
    const [paymentlist, setPaymentlist] = useState();
    const [payment, setPayment] = useState();
    const [paymentExtra, setPaymentExtra] = useState('');
    const [deliveryExtra, setDeliveryExtra] = useState('');
    const [thing, setThing] = useState(0);
    const [table, setTable] = useState();

    const [paymenttListData, setPaymentListData] = useState([]);
    
    const {setVersion, company} = useAPP();

    const toast = useRef(null);

    const requestExists = useRef(null);

    let uniqueId = '';
    
    //console.log(company);

    const getTotal = () => {
        const items = getCartList();
        
        const value = items.reduce((_c, _n) => {

            let itens = (_n.combo || []);
            itens = itens.filter(el => ~~el.quantity > 0);
            const valueItens = itens.reduce((_a, _i)=> {
                return _a + (parseFloat(_i.price) * ~~_i.quantity)
            }, 0);

            

            return _c + ((parseFloat(_n.price) * ~~_n.quantity) + (valueItens * ~~_n.quantity)  - (parseFloat(_n.descont) * ~~_n.quantity));
        } , 0);

        return value;
    }

    const getTotalItem = (_data) => {
        const items = _data;

        let itens = (items.combo || []);
            itens = itens.filter(el => ~~el.quantity > 0);
            const valueItens = itens.reduce((_a, _i)=> {
                return _a + (parseFloat(_i.price) * ~~_i.quantity)
            }, 0);
        
        return ((parseFloat(items.price) * ~~items.quantity) + (valueItens * ~~items.quantity)  - (parseFloat(items.descont) * ~~items.quantity));
    }

    const getDeliveryData = useCallback(() => {

        const getData = async () => {
            let list = [
                {label: 'Entregar na mesa', value:'Entregar na mesa', price: 0},
                {label: 'Retirar pedido', value:'Retirar pedido', price: 0}
            ];

            const address =  await userAddress();

            address?.data?.map(item=> {
                list.push({label:` ${item.logradouro}, ${item.numero}${item.complemento ? `- ${item.complemento}`: ''} - ${item.bairro} - ${item.cidade} - ${item.estado}`, value:` ${item.logradouro}, ${item.numero}${item.complemento ? `- ${item.complemento}`: ''} - ${item.bairro} - ${item.cidade} - ${item.estado}`, price: company?.taxa_entrega});
                return item;
            })

            setDeliverylist(list);

        }

        getData();

    }, [company])

    const getPaymentData = useCallback(() => {

        const getData = async () => {


            let list = [];

            list = company?.forma_pagamento?.filter(item => !item.type );

             list = (list || [])?.map(item => ({
                 label: item.nome, value: item.nome, data: item
             }));

            const paymentdata =  await userPayment(~~props.match.params.client);

            console.log('payment list', paymentdata);

            paymentdata?.data?.map(item=> {
                // const temp = company?.forma_pagamento?.filter(fp => ~~fp.id === item.forma );
                // if(temp.length > 0){
                //     list.push({label:`${temp[0].nome} - ${item.portador} - ${formatToCredidCard(item.numero)}- ${item.validade_mes}/${item.validade_ano}`, value:~~item.id, portador: item.portador});
                // }
                list.push({label:`${item.nome} - ${item.portador} - ${formatToCredidCard(item.numero)}- ${item.validade_mes}/${item.validade_ano}`, value:~~item.id, portador: item.portador, data: item});
                return item;
            })

            setPaymentlist(list);

        }

        getData();

    }, [company])

    const getValueDelivery = useCallback(() => {
        const d = deliverylist?.filter(item => item.value === delivery)[0];
        setDeliveryPrice(d ? d.price : 0);
    },[delivery, deliverylist])

    const closeRequest = async () => {

        setIsLoad(true);

        const date = new Date();
        uniqueId = `${props.match.params.client}${date.getDay()}${date.getMonth()}${date.getFullYear()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
        //const card =  paymentlist.filter(item => ~~item.value === ~~payment)[0];
        //console.log(payment, card);

        const currentPayment = paymentlist?.filter(item => ~~item.data.id === payment);


        const saveData = {
            numero: uniqueId,
            empresa_id: ~~props.match.params.client,
            endereco: `${delivery} ${table ? `: ${table}` : ''}` ,
            usuario_pagamento_id: currentPayment[0]?.data?.id,
            forma_pagamento: currentPayment[0]?.data?.forma_pagamento_nome,
            forma_pagamento_id: payment, 
            subtotal: getTotal(),
            total: getTotal() + parseFloat(deliveryPrice),
            troco: thing

        }

        if(!/retirar|mesa/gi.test(delivery)){

            const store_address = `${company.logradouro} ${company.bairro} ${company.cidade} ${company.estado}`; 
        
            const url = `${URL_GOOGLE_MAPS_GETDISTANCE}&destinations=${delivery}&origins=${store_address}`;

            const params = {
                method: 'GET',
                header: new Headers()
            }

            const maps_data_object =  await fetch(url, params);
            const maps_data = await maps_data_object.json();

            saveData.maps_data =  JSON.stringify(maps_data);
        }

        const result = await userRequestCreate(saveData);

        console.log('DATa', result);

        const listItens = [];

        if(result?.data?.request){

            if(requestExists.current){

                if(result?.data?.payment){
                    clearCart();
                    toast.current.show({severity: 'success', summary: 'Sucesso', detail: 'Pedido criado com sucesso.', onCallback:()=>props.history.push(`/${props.match.params.client}/user/request/view/${uniqueId}`)});
                }else{
                    toast.current.show({severity: 'erro', summary: 'Erro', detail: 'Houve um erro no seu pagamento.'});
                }

            }else{

                requestExists.current = result?.data?.request;

                getCartList().map(item => {

                    const saveDataItem = {
                        pedido_id: result?.data?.request,
                        produto_id: item.id,
                        informacao: item.description,
                        quantidade: item.quantity
                    }

                    listItens.push(userRequestCreateitem(saveDataItem));

                    item?.combo?.map(subitem => {

                        const saveDataSubItem = {
                            pedido_id: result.data.id,
                            produto_id: subitem.id,
                            informacao: subitem.description,
                            quantidade: subitem.quantity
                        }

                        if(~~subitem.quantity > 0)
                            listItens.push(userRequestCreateitem(saveDataSubItem));

                        return subitem;
                    })


                    return item;
                })

                await Promise.all(listItens);

                
                if(result?.data?.payment){
                    clearCart();
                    toast.current.show({severity: 'success', summary: 'Sucesso', detail: 'Pedido criado com sucesso.', onCallback:()=>props.history.push(`/${props.match.params.client}/user/request/view/${uniqueId}`)});
                }else{
                    toast.current.show({severity: 'erro', summary: 'Erro', detail: 'Houve um erro no seu pagamento.'});
                }

                setVersion(new Date().getTime());
            }
        }else{
            toast.current.show({severity: 'erro', summary: 'Erro', detail: 'Houve um erro ao finalizar seu pedido.'});
        }

        setIsLoad(false);

    }

    const verifyComplete = (e) => {
        if(e?.onCallback){
            e.onCallback();
        }
    }

    useEffect(()=>{

        const type = paymentlist?.filter(item => {
            const ex = new RegExp(`${payment}`,'gi');
            //item.value === ~~payment
            return ex.test(item.value);
        })[0];

        let _data = '';

        

         switch(type?.label?.toLowerCase()){
            case 'dinheiro':
                _data = <>
                    <div className="p-field p-mt-5">
                        <span className="p-float-label">
                            <InputNumber id="thing" value={thing} onChange={(e)=> setThing(e.value)} mode="currency" currency="BRL" locale="pt-BR" />
                            <label htmlFor="thing">Troco</label>
                        </span>
                    </div>
                </>
            break;
            default:
                setThing(0);
                _data = '';
        }

        setPaymentExtra(_data);

    },[payment, paymentlist, thing])


    useEffect(()=>{

        const type = deliverylist?.filter(item => {
            const ex = new RegExp(`${delivery}`,'gi');
            //item.value === ~~payment
            return ex.test(item.value);
        })[0];

        let _data = '';


         switch(type?.label?.toLowerCase()){
            case 'entregar na mesa':
                _data = <>
                    <div className="p-field p-mt-5">
                        <span className="p-float-label">
                            <InputText id="table" value={table} onChange={(e)=>{ setTable(e.target.value); console.log(e.target.value); }} />
                            <label htmlFor="table">Número</label>
                        </span>
                    </div>
                </>
            break;
            default:
                setTable();
                _data = '';
        }

        setDeliveryExtra(_data);

    },[table, delivery, deliverylist])

    useEffect(()=>{

        getValueDelivery();

    }, [delivery, getValueDelivery])

    useEffect(()=>{

        getDeliveryData();
        getPaymentData();

    }, [getDeliveryData, getPaymentData]);

    useEffect(()=>{

        const getPaymentList = async () => {
            const data = await paymentList(~~props.match.params.client);
            setPaymentListData(data?.data);
        }

        getPaymentList();

    }, [props]);

    return (
        <div className="page-cart-list">
            <div className="main-frame">
                <h3>
                    Carrinho de compras
                </h3>
                <div className="p-d-flex p-mb-4 p-mt-2 p-jc-end">
                    <Button onClick={()=>props.history.push(`/${props.match.params.client}`)} className="p-button-text" icon="pi pi-plus" label="Adicionar novo item" />
                </div>
                <div className="p-grid">
                    <div className="p-col-12">
                    {
                            getCartList().length > 0 ?
                                <div key={`toolbar-rigth-cart-`}>
                                    <Divider >
                                        <strong>Itens adicionados</strong>
                                    </Divider>
                                    <div style={{minWidth:'280px', width:'100%', maxHeight:'50vh', overflowY:'auto', overflowX:'hidden', fontSize:'0.8em'}}>
                                        {
                                            getCartList().map((item, id) => {
                                                return <div key={`cart-list-${id}`} className="p-grid p-m-1 p-ai-center" >
                                                    <div className="p-col-fixed" style={{width:'40px', textAlign:'center'}}>
                                                        <span style={{borderRadius: '50%', width:'22px', height:'22px', minWidth:'22px', minHeight:'22px', backgroundColor:'var(--green-100)', display:'flex', alignItems:'center', justifyContent:'center', fontSize:'0.7em'}}>
                                                            {id + 1}
                                                        </span>
                                                    </div>
                                                    <div className="p-col">
                                                        <div style={{fontSize:'0.8em'}}>{item.quantity} X {item.name}</div>
                                                        <div style={{margin:'5px 0', fontSize:'0.8em'}}>
                                                            {item.description}
                                                        </div>
                                                    </div>
                                                    <div className="p-col-fixed" style={{width:'90px', textAlign:'right'}}>
                                                        {formatToMoney(getTotalItem(item))}
                                                    </div>
                                                    <div className="p-col-fixed" style={{width:'40px'}}>
                                                        <Button onClick={(e) => { removeCartItem(item.id); setVersion(new Date().getTime())}} icon="pi pi-trash" className="p-button-text p-button-danger p-button-rounded" />
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <Divider />
                                    {
                                        isAuthenticated() ?
                                            <>
                                                <h3>
                                                    Local da entrega
                                                </h3>
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-md-9">
                                                        <Dropdown options={deliverylist} value={delivery} onChange={(e)=> setDelivery(e.value) } placeholder="Selecione o local de entrega" style={{width:'100%'}} />
                                                        <div>
                                                            {deliveryExtra}
                                                        </div>
                                                    </div>
                                                    <div className="p-col-12 p-md-3" style={{textAlign:'right'}}>
                                                        <Button onClick={()=>props.history.push({pathname:`/${props.match.params.client}/user/address/create`, state:{callback:`/${props.match.params.client}/user/cart`}})} label="Adicionar endereço" icon="pi pi-plus" className="p-button-text p-button-sm" />
                                                    </div>
                                                </div>
                                                <h3>
                                                    Forma de pagamento
                                                </h3>
                                                <div className="payment-list-enabled">
                                                    {
                                                        paymenttListData?.map(item => {
                                                            return (<span>
                                                                {item.nome}
                                                            </span>)
                                                        })
                                                    }
                                                </div>
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-md-9">
                                                        <Dropdown options={paymentlist} value={payment} onChange={(e)=> setPayment(e.value) } placeholder="Selecione a forma de pagamento" style={{width:'100%'}} />
                                                        <div>
                                                            {paymentExtra}
                                                        </div>
                                                    </div>
                                                    <div className="p-col-12 p-md-3" style={{textAlign:'right'}}>
                                                        <Button onClick={()=>props.history.push({pathname:`/${props.match.params.client}/user/payment/create`, state:{callback:`/${props.match.params.client}/user/cart`}})} label="Adicionar pagamento" icon="pi pi-plus" className="p-button-text p-button-sm" />
                                                    </div>
                                                </div>
                                                <Divider />
                                            </>
                                        :
                                            ''
                                    }
                                    <div className="p-gridp-m-2 p-jc-end" style={{textAlign:'right'}}>
                                        <div style={{margin:'5px 0', fontSize:'0.8em'}}><strong>Sub total</strong>: {formatToMoney(getTotal())}</div>
                                        <div style={{margin:'5px 0', fontSize:'0.8em'}}><strong>Frete</strong>: {formatToMoney(deliveryPrice)}</div>
                                        <div><strong>Total</strong>: {formatToMoney(getTotal() + parseFloat(deliveryPrice))}</div>
                                    </div>
            
                                    <div className="p-d-flex p-m-2 p-jc-center">
                                    {
                                        isAuthenticated() ?
                                            <Button loading={isLoad} onClick={()=> closeRequest() } label="Finalizar" className="p-button-danger p-button-sm" />
                                        :
                                            <>
                                                <Button onClick={(e) => props.history.push(`/${props.match.params.client}/user/sigup`)} style={{width:'100px'}} label="Cadastar" className="p-button p-button-sm p-mr-2" />
                                                <Button onClick={(e) => props.history.push(`/${props.match.params.client}/user/login`)} style={{width:'100px'}} label="Login" className="p-button-secondary p-button-sm" />
                                            </>
                                    }
                                    </div>
                                </div>
                            :
                            <p>Não há itens no carrinho.</p>
                        }
                    </div>
                </div>
            </div>
            <Toast ref={toast} position="bottom-center" onHide={(e)=> verifyComplete(e)} onRemove={(e)=> verifyComplete(e)} />
        </div>
    )
}